import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreCommonService } from '../../core/core-common.service';

@Component({
  selector: 'app-system-requirements',
  templateUrl: './system-requirements.component.html',
  styleUrls: ['./system-requirements.component.css']
})
export class SystemRequirementsComponent implements OnInit {

  copyrightYear: any;

  language : string = "en";

  constructor(
    private route: ActivatedRoute,
    private coreCommonService: CoreCommonService,
  ) { }

  ngOnInit(): void {

    this.copyrightYear = new Date().getFullYear();

    this.route.queryParams
      .subscribe(params => {
        if(params.hasOwnProperty("lang")){
          this.language = params.lang;
        }else{
          this.language = "EN";
        }

        this.coreCommonService.setLanguage(this.language.toLocaleUpperCase());
      }
    );
  }

}
