export class LoginModel {
    public userName: String;
    public password: String;
    constructor(
        sUserName: String,
        sPassword: String,
    ) {
        this.userName = sUserName;
        this.password = sPassword;
    }
}


export class HospitalSelectionModel {
    public HospitalName: String;
    public HospitalId: Number;
    constructor(
        sHospitalName: String,
        sHospitalId: Number,
    ) {
        this.HospitalName = sHospitalName;
        this.HospitalId = sHospitalId;
    }
}

export class DoctorListElement {
    public doctorName: String;
    public doctorId: Number;
    constructor(
        sDoctorName: String,
        nDoctorId: Number,
    ) {
        this.doctorName = sDoctorName;
        this.doctorId = nDoctorId;
    }
}



export class FrontDeskListElement {
    public userName: String;
    public userId: Number;
    constructor(
        sUserName: String,
        nUserId: Number,
    ) {
        this.userName = sUserName;
        this.userId = nUserId;
    }
}




export class LearnerListElement {
    public name: String;
    public age: Number;
    public gender: String;
    public lastCheckup: Date;
    public userId: Number;
    constructor(
        sName: String,
        nAge: Number,
        sGender: String,
        dLastCheckup: Date,
        nUserId: Number
    ) {
        this.name = sName;
        this.age = nAge;
        this.gender = sGender;
        this.lastCheckup = dLastCheckup;
        this.userId = nUserId;
    }
}





export class UserManagementElement {

    public Firstname: string;
    public Middlename: string;
    public Lastname: string;
    public Gender: string;
    public Speciality: string;
    public Profession: string;
    public DateOfBirth: any;
    public RegnNumber: number;
    public RegnBody: string;
    public City: string;
    public State: string;
    public Country: string;
    public Address: string;
    public UserLanguage: string;
    public ContactNumber: number;
    public Email: string;
    public CustomerId: number;
    public OrganizationName: string;
    public RoleId: number;
    public UserId: number;

    constructor(
        sFirstname: string,
        sMiddlename: string,
        sLastname: string,
        sGender: string,
        sSpeciality: string,
        sProfession: string,
        dDateOfBirth: any,
        nRegnNumber: number,
        sRegnBody: string,
        sCity: string,
        sState: string,
        sCountry: string,
        sAddress: string,
        nUserLanguage: string,
        nContactNumber: number,
        sEmail: string,
        nCustomerId: number,
        sOrganizationName: string,
        nRoleId: number,
        nUserId: number,
    ) {
        this.Firstname = sFirstname;
        this.Middlename = sMiddlename;
        this.Lastname = sLastname;
        this.Gender = sGender;
        this.Speciality = sSpeciality;
        this.Profession = sProfession;
        this.DateOfBirth = dDateOfBirth;
        this.RegnNumber = nRegnNumber;
        this.RegnBody = sRegnBody;
        this.City = sCity;
        this.State = sState;
        this.Country = sCountry;
        this.Address = sAddress;
        this.UserLanguage = nUserLanguage;
        this.ContactNumber = nContactNumber;
        this.Email = sEmail;
        this.CustomerId = nCustomerId;
        this.OrganizationName = sOrganizationName;
        this.RoleId = nRoleId;
        this.UserId = nUserId;
    }
}

export class UserData{
    public CustomerId : number;
    public UserCustomerId : number;
    public UserDetailsId : number;
    public UserId : number;
    public Firstname : string;
    public Middlename : string;
    public Lastname : string;
    public Name : string;
    public OrganizationName : string;
    public Speciality : string;
    public Profession : string;
    public DateOfBirth : Date;
    public RegnNumber : string;
    public RegnBody : string;
    public Age : number;
    public Gender : string;
    public Address : string;
    public City : string;
    public State : string;
    public Country : number;
    public CountryName : string;
    public ContactNumber : string;
    public Email : string;
    public IsActive : boolean;
    public RoleId : number;
    public More : boolean;
    public LoginId : string;
    public Password: string;
    public SupervisorName : string;
    public SupervisorEmail : string;
    public Custom1 : string;
    public Custom2 : string;
    public ShooperID : number;
    public TempUserDetailsId : number;
    public UserKey : string;
    public Lang : string;
    public Convention : boolean;

    constructor(){
        this.CustomerId = 0;
        this.UserCustomerId = 0;
        this.UserDetailsId = 0;
        this.UserId = 0;
        this.Firstname = "";
        this.Middlename = "";
        this.Lastname = "";
        this.Name = "";
        this.OrganizationName = "";
        this.Speciality = "";
        this.Profession = "";
        this.DateOfBirth = null;
        this.RegnNumber = "";
        this.RegnBody = "";
        this.Age = 0;
        this.Gender = null;
        this.Address = "";
        this.City = "";
        this.State = "";
        this.Country = null;
        this.CountryName = "";
        this.ContactNumber = "";
        this.Email = "";
        this.IsActive = true;
        this.RoleId = 0;
        this.More =  true;
        this.LoginId = "";
        this.Password = "";
        this.SupervisorName = null;
        this.SupervisorEmail = null;
        this.Custom1 = "";
        this.Custom2 = "";
        this.ShooperID = 0;
        this.TempUserDetailsId = 0;
        this.UserKey = "";
        this.Lang = "DE";
        this.Convention = false;
    }
}


//
export class clinicalFindingsData {
    public IPD: number;
    public R_Sph: number;
    public R_Cyl: number;
    public R_Axis: number;
    public R_Add: number;
    public R_VA_Distance: number;
    public R_DistanceLogMARValue: string;
    public R_VA_Near: number;
    public R_NearLogMARValue: number;
    public R_ClinicalDiag: string;
    public L_Sph: number;
    public L_Cyl: number;
    public L_Axis: number;
    public L_Add: number;
    public L_VA_Distance: number;
    public L_DistanceLogMARValue: string;
    public L_VA_Near: number;
    public L_NearLogMARValue: number;
    public L_ClinicalDiag: string;
    public TestedVerified: string;
    public GlassesStatus: string;
    public VAInputType: string;

    constructor(
        IPD: number,
        R_Sph: number,
        R_Cyl: number,
        R_Axis: number,
        R_Add: number,
        R_VA_Distance: number,
        R_DistanceLogMARValue: string,
        R_VA_Near: number,
        R_NearLogMARValue: number,
        R_ClinicalDiag: string,
        L_Sph: number,
        L_Cyl: number,
        L_Axis: number,
        L_Add: number,
        L_VA_Distance: number,
        L_DistanceLogMARValue: string,
        L_VA_Near: number,
        L_NearLogMARValue: number,
        L_ClinicalDiag: string,
        TestedVerified: string,
        GlassesStatus: string,
        VAInputType: string
    ) {
        this.IPD = IPD;
        this.R_Sph = R_Sph;
        this.R_Cyl = R_Cyl;
        this.R_Axis = R_Axis;
        this.R_Add = R_Add;
        this.R_VA_Distance = R_VA_Distance;
        this.R_DistanceLogMARValue = R_DistanceLogMARValue;
        this.R_VA_Near = R_VA_Near;
        this.R_NearLogMARValue = R_NearLogMARValue;
        this.R_ClinicalDiag = R_ClinicalDiag;
        this.L_Sph = L_Sph;
        this.L_Cyl = L_Cyl;
        this.L_Axis = L_Axis;
        this.L_Add = L_Add;
        this.L_VA_Distance = L_VA_Distance;
        this.L_DistanceLogMARValue = L_DistanceLogMARValue;
        this.L_VA_Near = L_VA_Near;
        this.L_NearLogMARValue = L_NearLogMARValue;
        this.L_ClinicalDiag = L_ClinicalDiag;
        this.TestedVerified = TestedVerified;
        this.GlassesStatus = GlassesStatus;
        this.VAInputType = VAInputType;
    }
}


export class UserProfile {
    public Firstname: string;
    public Middlename: string;
    public Lastname: string;
    public Gender: string;
    public Speciality: string;
    public Profession: string;
    public DateOfBirth: any;
    public RegnNumber: number;
    public RegnBody: string;
    public City: string;
    public State: string;
    public Country: string;
    public Address: string;
    public ContactNumber: number;
    public Email: string;

    constructor(
        sFirstname: string,
        sMiddlename: string,
        sLastname: string,
        sGender: string,
        sSpeciality: string,
        sProfession: string,
        dDateOfBirth: any,
        nRegnNumber: number,
        sRegnBody: string,
        sCity: string,
        sState: string,
        sCountry: string,
        sAddress: string,
        nContactNumber: number,
        sEmail: string
    ) {
        this.Firstname = sFirstname;
        this.Middlename = sMiddlename;
        this.Lastname = sLastname;
        this.Gender = sGender;
        this.Speciality = sSpeciality;
        this.Profession = sProfession;
        this.DateOfBirth = dDateOfBirth;
        this.RegnNumber = nRegnNumber;
        this.RegnBody = sRegnBody;
        this.City = sCity;
        this.State = sState;
        this.Country = sCountry;
        this.Address = sAddress;
        this.ContactNumber = nContactNumber;
        this.Email = sEmail;
    }
}
export class questionaire {
    public questionOption: string
    constructor(
        questionOption: string
    ) {
        this.questionOption = questionOption;
    }
}




export class LicenseModel {
    public LicenseCount: number;
    public FreeLicenseCount: number;
    public CustomerId:number;
    public Status:number;
    public TotalCost:number;
    public PaymentType:number;
    public UserId:number;    
    constructor(
        nLicenseCount: number,
        nFreeLicenseCount: number,        
        nCustomerId: number,
        nStatus: number,
        nTotalCost: number,
        nPaymentType: number,
        nUserId: number,
    ) {
        this.LicenseCount = nLicenseCount;
        this.FreeLicenseCount = nFreeLicenseCount;
        this.CustomerId = nCustomerId;
        this.Status = nStatus;
        this.TotalCost = nTotalCost;
        this.PaymentType = nPaymentType;
        this.UserId = nUserId;
    }
}
export class CustomerManagementElement {

    public Firstname: string;
    public Middlename: string;
    public Lastname: string;
    public Gender: string;
    public Speciality: string;
    public Profession: string;
    public DateOfBirth: any;
    public RegnNumber: number;
    public RegnBody: string;
    public City: string;
    public State: string;
    public Country: string;
    public Address: string;
    public ContactNumber: number;
    public Email: string;
    public CustomerId: number;
    public OrganizationName: string;
    public RoleId: number;
    public UserId: number;

    constructor(
        sFirstname: string,
        sMiddlename: string,
        sLastname: string,
        sGender: string,
        sSpeciality: string,
        sProfession: string,
        dDateOfBirth: any,
        nRegnNumber: number,
        sRegnBody: string,
        sCity: string,
        sState: string,
        sCountry: string,
        sAddress: string,
        nContactNumber: number,
        sEmail: string,
        nCustomerId: number,
        sOrganizationName: string,
        nRoleId: number,
        nUserId: number
    ) {
        this.Firstname = sFirstname;
        this.Middlename = sMiddlename;
        this.Lastname = sLastname;
        this.Gender = sGender;
        this.Speciality = sSpeciality;
        this.Profession = sProfession;
        this.DateOfBirth = dDateOfBirth;
        this.RegnNumber = nRegnNumber;
        this.RegnBody = sRegnBody;
        this.City = sCity;
        this.State = sState;
        this.Country = sCountry;
        this.Address = sAddress;
        this.ContactNumber = nContactNumber;
        this.Email = sEmail;
        this.CustomerId = nCustomerId;
        this.OrganizationName = sOrganizationName;
        this.RoleId = nRoleId;
        this.UserId = nUserId;
    }
}


export class PaymentReceived {
    public LicenseCount: number;
    public FreeLicenseCount: number;
    public CustomerId:number;
    public Status:number;
    public TotalCost:number;
    public PaymentType:number;
    public TransactionNumber:string;
    public UserId:number;   
    public PaymentDate:string; 
    constructor(
        nLicenseCount: number,
        nFreeLicenseCount: number,        
        nCustomerId: number,
        nStatus: number,
        nTotalCost: number,
        nPaymentType: number,
        sTransactionNumber:string,
        nUserId: number,
        nPaymentDate:string
    ) {
        this.LicenseCount = nLicenseCount;
        this.FreeLicenseCount = nFreeLicenseCount;
        this.CustomerId = nCustomerId;
        this.Status = nStatus;
        this.TotalCost = nTotalCost;
        this.PaymentType = nPaymentType;
        this.TransactionNumber = sTransactionNumber;
        this.UserId = nUserId;
        this.PaymentDate = nPaymentDate;
    }
}

export class CustomerProfile {
    public OrganizationName: string;
    public ContactPersonName: string;
    public EmailId: string;
    public MobileNo: number;
    public LandlineNo: number;
    public Country: string;
    public City: string;
    public State: string;
    public Address: string;
    public AdminEmailId: string;
    public GST: string;
    public UserId: number;
    public CustomerCode:string;
    public FileId:number;
    constructor(
        sOrganizationName: string,
        sContactPersonName: string,
        sEmailId: string,
        nMobileNo: number,
        nLandlineNo: number,
        sCountry: string,
        sCity: string,
        sState: string,
        sAddress: string,
        sAdminEmailId: string,
        sGST: string,
        nUserId: number,
        sCustomerCode:string,
        nFileId:number
    ) {
        this.OrganizationName = sOrganizationName;
        this.ContactPersonName = sContactPersonName;
        this.EmailId = sEmailId;
        this.MobileNo = nMobileNo;
        this.LandlineNo = nLandlineNo
        this.Country = sCountry;
        this.City = sCity;
        this.State = sState;
        this.Address = sAddress;
        this.AdminEmailId = sAdminEmailId;
        this.GST = sGST;
        this.UserId = nUserId;
        this.CustomerCode = sCustomerCode;
        this.FileId = nFileId;
    }
}

export class PageWizardStep {
    public stepIndex : number = 0;
    public isComplete : boolean = false;
}

export class ARMediumViewModel{
    public ARMediumId : number;
    public MediumName : string;
    public ARObjectType : number;
    public MediumFileRef : number;
    public MediumFilePath : string;
    public MediumFileName : string;
    public MediumFileSize : number;
    public CustomerId : number;
    public ARProjectReferenceCount : number;

    constructor(ARMediumId : number = 0, MediumName : string = "", ARObjectType : number = 0, MediumFileRef : number = 0, MediumFilePath : string = "", MediumFileName : string = "", MediumFileSize : number = 0, CustomerId : number = 0, ARProjectReferenceCount : number = 0){
        this.ARMediumId = ARMediumId;
        this.MediumName = MediumName;
        this.ARObjectType = ARObjectType;
        this.MediumFileRef = MediumFileRef;
        this.MediumFilePath = MediumFilePath;
        this.MediumFileName = MediumFileName;
        this.MediumFileSize = MediumFileSize;
        this.CustomerId = CustomerId;
        this.ARProjectReferenceCount = ARProjectReferenceCount;
    }
}

export class ARMediumsOfCustomer{
    public CustomerId : number;
    public CustomerARMediums : ARMediumViewModel[];

    constructor(CustomerId : number = 0, CustomerARMediums : ARMediumViewModel[] = []){
        this.CustomerId = CustomerId;
        this.CustomerARMediums = CustomerARMediums;
    }
}

export class ARMediumAddUpdateRequestModel{
    public ARMediumId : number;
    public MediumName : string;
    public MediumFileRef : number;
    public CustomerId : number;

    constructor(ARMediumId : number = 0, MediumName : string = "", MediumFileRef : number = 0, CustomerId : number = 0){
        this.ARMediumId = ARMediumId;
        this.MediumName = MediumName,
        this.MediumFileRef = MediumFileRef;
        this.CustomerId = CustomerId;
    }
}

export class ARMediumGetARMediumInfoRequestModel{
    public ARMediumId : number;
    public ARMediumInfo : ARMediumViewModel;

    constructor(ARMediumId : number = 0, ARMediumInfo : ARMediumViewModel = new ARMediumViewModel()){
        this.ARMediumId = ARMediumId;
        this.ARMediumInfo = ARMediumInfo;
    }
}

export class ARImageTrackerViewModel{
    public ARImageTrackerId : number;
    public ImageTrackerName : string;
    public ARTrackerType : number;
    public ImageFileRef : number;
    public ImageFilePath : string;
    public ImageFileName : string;
    public ImagePatternRef : number;
    public ImagePatternFilePath : string;
    public ImagePatternFileName : string;
    public CustomerId : number;
    public ARProjectReferenceCount : number;

    constructor(ARImageTrackerId : number = 0, ImageTrackerName : string = "", ARTrackerType : number = 0, ImageFileRef : number = 0, ImageFilePath : string = "", ImageFileName : string = "", ImagePatternRef : number = 0, ImagePatternFilePath : string = "", ImagePatternFileName : string = "", CustomerId : number = 0, ARProjectReferenceCount : number = 0){
        this.ARImageTrackerId = ARImageTrackerId;
        this.ImageTrackerName = ImageTrackerName;
        this.ARTrackerType = ARTrackerType;
        this.ImageFileRef = ImageFileRef;
        this.ImageFilePath = ImageFilePath;
        this.ImageFileName = ImageFileName;
        this.ImagePatternRef = ImagePatternRef;
        this.ImagePatternFilePath = ImagePatternFilePath;
        this.ImagePatternFileName = ImagePatternFileName;
        this.CustomerId = CustomerId;
        this.ARProjectReferenceCount = ARProjectReferenceCount;
    }
}

export class ARImageTrackerAddUpdateRequestModel{
    public ArImageTrackerId : number;
    public ImageTrackerName : string;
    public ImageTrackerFileRef : number;
    public ImagePatternFileRef : number;
    public ImageTrackerTypeRef : number;
    public CustomerId : number;

    constructor(ArImageTrackerId : number = 0, ImageTrackerName : string = "", ImageTrackerFileRef : number = 0, ImagePatternFileRef : number = 0, ImageTrackerTypeRef : number = 0, CustomerId : number = 0){
        this.ArImageTrackerId = ArImageTrackerId;
        this.ImageTrackerName = ImageTrackerName;
        this.ImageTrackerFileRef = ImageTrackerFileRef;
        this.ImagePatternFileRef = ImagePatternFileRef;
        this.ImageTrackerTypeRef = ImageTrackerTypeRef;
        this.CustomerId = CustomerId;
    }
}

export class ARImageTrackerGetARImageTrackerInfoRequestModel{
    public ARImageTrackerId : number;
    public ARImageTrackerInfo : ARImageTrackerViewModel;

    constructor(ARImageTrackerId : number = 0, ARImageTrackerInfo : ARImageTrackerViewModel = new ARImageTrackerViewModel()){
        this.ARImageTrackerId = ARImageTrackerId;
        this.ARImageTrackerInfo = ARImageTrackerInfo;
    }
}

export class ARImageTrackersOfCustomer{
    public CustomerId : number;
    public CustomerARImageTrackers : ARImageTrackerViewModel[];

    constructor(CustomerId : number = 0, CustomerARImageTrackers : ARImageTrackerViewModel[] = []){
        this.CustomerId = CustomerId;
        this.CustomerARImageTrackers = CustomerARImageTrackers;
    }
}

export class ARTrackedObjectViewModel{
    public ARTrackedObjectId : number;
    public TrackedObjectName : string;
    public Medium : ARMediumViewModel;
    public ImageTracker : ARImageTrackerViewModel;
    public RealWorldWidth : number;
    public PosOffsetX : number;
    public PosOffsetY : number;
    public PosOffsetZ : number;
    public RotOffsetX : number;
    public RotOffsetY : number;
    public RotOffsetZ : number;
    public ScaleX : number;
    public ScaleY : number;
    public ScaleZ : number;
    public ExtraProperties : object;
    public CustomerId : number;

    constructor(ARTrackedObjectId : number = 0, TrackedObjectName : string = "", Medium : ARMediumViewModel = new ARMediumViewModel(), ImageTracker : ARImageTrackerViewModel = new ARImageTrackerViewModel(), 
    RealWorldWidth : number = 0.1, PosOffsetX : number = 0.0, PosOffsetY : number = 0.0, PosOffsetZ : number = 0.0, RotOffsetX : number = 0.0, RotOffsetY : number = 0.0, RotOffsetZ : number = 0.0,
    ScaleX : number = 1.0, ScaleY : number = 1.0, ScaleZ : number = 1.0, ExtraProperties : object = {}, CustomerId : number = 0){
        this.ARTrackedObjectId = ARTrackedObjectId;
        this.TrackedObjectName = TrackedObjectName;
        this.Medium = Medium;
        this.ImageTracker = ImageTracker;
        this.RealWorldWidth = RealWorldWidth;
        this.PosOffsetX = PosOffsetX;
        this.PosOffsetY = PosOffsetY;
        this.PosOffsetZ = PosOffsetZ;
        this.RotOffsetX = RotOffsetX;
        this.RotOffsetY = RotOffsetY;
        this.RotOffsetZ = RotOffsetZ;
        this.ScaleX = ScaleX;
        this.ScaleY = ScaleY;
        this.ScaleZ = ScaleZ;
        this.ExtraProperties = ExtraProperties;
        this.CustomerId = CustomerId;
    }
}

export class ARTrackedObjectAdvancedSettings{
    public RotOffsetX : number;
    public RotOffsetY : number;
    public RotOffsetZ : number;
    public ExtraProperties : object;
    public static STATIC_PROPERTIES : string[] = ["RotOffsetX", "RotOffsetY", "RotOffsetZ"];

    constructor(RotOffsetX : number = 0, RotOffsetY : number = 0, RotOffsetZ : number = 0, ExtraProperties : object = {}){
        this.RotOffsetX = RotOffsetX;
        this.RotOffsetY = RotOffsetY;
        this.RotOffsetZ = RotOffsetZ;
        this.ExtraProperties = ExtraProperties;

        //Default Properties
        this.addExtraBooleanProperty("ModelInteraction", true);
        this.addExtraBooleanProperty("RestartVideoOnMarkerLost", false);
        this.addExtraBooleanProperty("TrackExclusively", true);
    }

    public static parseFromObject (jsonObject : object)  {
        let returnObject = new ARTrackedObjectAdvancedSettings();

        try{
            for (var key in jsonObject) {
                if(ARTrackedObjectAdvancedSettings.STATIC_PROPERTIES.indexOf(key) != -1){
                    if(key == "RotOffsetX"){
                        returnObject.RotOffsetX = jsonObject[key];
                    }
        
                    if(key == "RotOffsetY"){
                        returnObject.RotOffsetX = jsonObject[key];
                    }
        
                    if(key == "RotOffsetZ"){
                        returnObject.RotOffsetX = jsonObject[key];
                    }
                }else{
                    returnObject.addExtraProperty(key, jsonObject[key]);
                }
            }
        }catch(ex){
            console.log(ex);
            returnObject = new ARTrackedObjectAdvancedSettings();
        }

        return returnObject;
    }

    public addExtraStringProperty(propertyName : string, propertyValue : string){
        this.addExtraProperty(propertyName, propertyValue);
    }

    public addExtraNumberProperty(propertyName : string, propertyValue : number){
        this.addExtraProperty(propertyName, propertyValue);
    }

    public addExtraBooleanProperty(propertyName : string, propertyValue : boolean){
        this.addExtraProperty(propertyName, propertyValue);
    }

    private addExtraProperty(propertyName : string, propertyValue : any){
        this.ExtraProperties[propertyName] = propertyValue;
    }

    public getPropertyValue(propertyName : string) : any{
        if(this.ExtraProperties.hasOwnProperty(propertyName)){
            return this.ExtraProperties[propertyName];
        }

        //If the Object doesn't have the property, check if the default Object has this property and return the default value
        let defaultProperties : ARTrackedObjectAdvancedSettings = new ARTrackedObjectAdvancedSettings();

        if(defaultProperties.ExtraProperties.hasOwnProperty(propertyName)){
            this.addExtraProperty(propertyName, defaultProperties.ExtraProperties[propertyName]);

            return defaultProperties.ExtraProperties[propertyName];
        }

        return null;
    }

    public setPropertyValue(propertyName: string, value : any){
        if(this.ExtraProperties.hasOwnProperty(propertyName)){
            this.ExtraProperties[propertyName] = value;
        }
    }
}


export class ARProjectModelView{
    public ARProjectId : number;
    public ProjectName : string;
    public ProjectDescription : string;
    public ProjectCode : string;
    public DevelopmentCode : string;
    public ProjectState : number;
    public CustomerId : number;
    public TrackedObjects : ARTrackedObjectViewModel[];
    public EngineReference : number;
    public EngineName : string;
    public ProjectImageReference : number;
    public ProjectImagePath : string;
    public ProjectLanguage : number;
    public ProjectLangugeShort : string;
    public HasReports : boolean;
    public PublishDirectURL : string;
    public DevelopmentDirectURL : string
    public ProjectLogs : ARProjectChangeLogCollectionModelView[];

    constructor(ARProjectId : number = 0,ProjectName : string = "", ProjectDescription : string = "", ProjectCode : string = "", DevelopmentCode : string = "", ProjectState : number = 0, 
        CustomerId : number = 0, TrackedObjects : ARTrackedObjectViewModel[] = [], EngineReference : number = 0, EngineName : string = "", ProjectImageReference : number = 0, ProjectImagePath : string = "",
        ProjectLanguage : number = 0, ProjectLangugeShort : string = "", HasReports : boolean = false, PublishDirectURL : string = "", DevelopmentDirectURL : string = "", ProjectLogs : ARProjectChangeLogCollectionModelView[] = []){
        this.ARProjectId = ARProjectId;
        this.ProjectName = ProjectName;
        this.ProjectDescription = ProjectDescription;
        this.ProjectCode = ProjectCode;
        this.DevelopmentCode = DevelopmentCode;
        this.ProjectState = ProjectState;
        this.CustomerId = CustomerId;
        this.TrackedObjects = TrackedObjects;
        this.EngineReference = EngineReference;
        this.EngineName = EngineName;
        this.ProjectImageReference = ProjectImageReference;
        this.ProjectImagePath = ProjectImagePath;
        this.ProjectLanguage = ProjectLanguage;
        this.ProjectLangugeShort = ProjectLangugeShort;
        this.HasReports = HasReports;
        this.PublishDirectURL = PublishDirectURL;
        this.DevelopmentDirectURL = DevelopmentDirectURL;
        this.ProjectLogs = ProjectLogs;
    }
}

export class ARProjectAddUpdateRequestModel{
    public ARProjectId : number;
    public ProjectName : string;
    public ProjectDescription : string;
    public TrackedObjects : ARTrackedObjectViewModel[];
    public CustomerId : number;
    public EngineReference : number;
    public ProjectImageReference : number;
    public ProjectLanguageReference : number;

    constructor(ARProjectId : number = 0,ProjectName : string = "", ProjectDescription : string = "", CustomerId : number = 0, TrackedObjects : ARTrackedObjectViewModel[] = [], 
    EngineReference : number = 0, ProjectImageReference : number = 0, ProjectLanguageReference : number = 0){
        this.ARProjectId = ARProjectId;
        this.ProjectName = ProjectName;
        this.ProjectDescription = ProjectDescription;
        this.TrackedObjects = TrackedObjects;
        this.CustomerId = CustomerId;
        this.EngineReference = EngineReference;
        this.ProjectImageReference = ProjectImageReference;
        this.ProjectLanguageReference = ProjectLanguageReference;
    }

}

export class ChangeARProjectStateRequestModel{
    public ARProjectId : number;
    public CustomerId : number;
    public StateId : number;

    constructor(ARProjectId : number = 0, CustomerId : number = 0, StateId : number = 0){
        this.ARProjectId = ARProjectId;
        this.CustomerId = CustomerId;
        this.StateId = StateId
    }
}

export class ARProjectDeleteRequestModel{
    public CustomerId : number;
    public ARProjectId : number;

    constructor(CustomerId : number = 0, ARProjectId : number = 0){
        this.CustomerId = CustomerId;
        this.ARProjectId = ARProjectId;
    }
}

export class ARProjectsOfCustomer{
    public CustomerId : number;
    public CustomerARProjects : ARProjectModelView[];

    constructor(CustomerId : number = 0, CustomerARProjects : ARProjectModelView[] = []){
        this.CustomerId = CustomerId;
        this.CustomerARProjects = CustomerARProjects;
    }
}

export class ARProjectGetARProjectInfoRequestModel{
    public ARProjectId : number;
    public ARProjectInfo : ARProjectModelView;

    constructor(ARProjectId : number = 0, ARProjectInfo : ARProjectModelView = new ARProjectModelView()){
        this.ARProjectId = ARProjectId;
        this.ARProjectInfo = ARProjectInfo;
    }
}

export class ARPlaylistModelView{
    public ARPlaylistId : number;
    public PlaylistTitle : string;
    public PlaylistDescription : string;
    public AssignedARProjects : ARProjectModelView[];

    constructor(ARPlaylistId : number = 0, PlaylistTitle : string = "", PlaylistDescription : string = "", AssignedARProjects : ARProjectModelView[] = []){
        this.ARPlaylistId = ARPlaylistId;
        this.PlaylistTitle = PlaylistTitle;
        this.PlaylistDescription = PlaylistDescription;
        this.AssignedARProjects = AssignedARProjects;
    }
}

export class ARPlaylistGetARPlaylistInfoRequestModel{
    public ARPlaylistId : number;
    public ARPlaylistInfo : ARPlaylistModelView;

    constructor(ARPlaylistId : number = 0, ARPlaylistInfo : ARPlaylistModelView = new ARPlaylistModelView()){
        this.ARPlaylistId = ARPlaylistId;
        this.ARPlaylistInfo = ARPlaylistInfo;
    }
}

export class ARPlaylistDeleteRequestModel{
    public CustomerId : number;
    public ARPlaylistId : number;

    constructor(CustomerId : number = 0, ARPlaylistId : number = 0){
        this.CustomerId = CustomerId;
        this.ARPlaylistId = ARPlaylistId;
    }
}

export class ARPlaylistAddUpdateRequestModel{
    public ARPlaylistId : number;
    public PlaylistTitle : string; 
    public PlaylistDescription : string;
    public PlaylistARProjects : ARProjectModelView[];
    public CustomerId : number;

    constructor(ARPlaylistId : number = 0, PlaylistTitle : string = "", PlaylistDescription : string = "", PlaylistARProjects : ARProjectModelView[] = [], CustomerId : number = 0){
        this.ARPlaylistId = ARPlaylistId;
        this.PlaylistTitle = PlaylistTitle;
        this.PlaylistDescription = PlaylistDescription;
        this.PlaylistARProjects = PlaylistARProjects;
        this.CustomerId = CustomerId;
    }
}

export class ARPlaylistsOfCustomer{
    public CustomerId : number;
    public CustomerARPlaylists : ARPlaylistModelView[];

    constructor(CustomerId : number = 0, CustomerARPlaylists : ARPlaylistModelView[] = []){
        this.CustomerId = CustomerId;
        this.CustomerARPlaylists = CustomerARPlaylists;
    }
}

export class AREngineViewModel{
    public AREngineId : number;
    public EngineName : string;
    public VersionPath : string;

    constructor(AREngineId : number = 0,EngineName : string = "", VersionPath : string = ""){
        this.AREngineId = AREngineId;
        this.EngineName = EngineName;
        this.VersionPath = VersionPath;
    }
}

export class ARReportsModelView{
    public TrackingProjectId : number;
    public ARProjectRef : number;
    public ViewedTrackedObjects : number[];
    public DeviceID : string;
    public DeviceTypeRef : number;
    public AccessDate : Date;

    constructor(TrackingProjectId : number = 0, ARProjectRef : number = 0, ViewedTrackedObjects : number[] = [], DeviceID : string = "",DeviceTypeRef : number = 0, AccessDate : Date = new Date()){
        this.TrackingProjectId = TrackingProjectId;
        this.ARProjectRef = ARProjectRef;
        this.ViewedTrackedObjects = ViewedTrackedObjects;
        this.DeviceID = DeviceID;
        this.DeviceTypeRef = DeviceTypeRef;
        this.AccessDate = AccessDate;
    }
}

export class OSReportEntry{
    public DeviceTypeRef : number;
    public TotalViews : number;

    constructor(DeviceTypeRef : number = 0, TotalViews : number = 0){
        this.DeviceTypeRef = DeviceTypeRef;
        this.TotalViews = TotalViews;
    }
}

export class MediumTrackingEntry{
    public MediumRef : ARMediumViewModel;
    public TotalViews : number;
    public OSBasedViews : OSReportEntry[];

    constructor(MediumRef : ARMediumViewModel = new ARMediumViewModel(), TotalViews : number = 0, OSBasedViews : OSReportEntry[] = []){
        this.MediumRef = MediumRef;
        this.TotalViews = TotalViews;
        this.OSBasedViews = OSBasedViews;
    }
}

export class ReportDateFilter{
    public StartDate : Date;
    public EndDate : Date; 

    constructor(StartDate : Date = new Date(2020, 1, 1, 0, 0 ,0 ,0), EndDate : Date = new Date(2070, 1, 1, 0, 0 ,0 ,0)){
        this.StartDate = StartDate;
        this.EndDate = EndDate;
    }
}

export class ARProjectGeneralTrackingReport{
    public ARProjectRef : number;
    public ARProjectName : string;
    public TotalViews : number;
    public UniqueViews : number;
    public OSBasedViews : OSReportEntry[];
    public MediumBasedViews : MediumTrackingEntry[];
    public TotalFileSize : number;

    constructor(ARProjectRef : number = 0,  ARProjectName : string = "", TotalViews : number = 0, UniqueViews : number = 0, OSBasedViews : OSReportEntry[] = [], MediumBasedViews : MediumTrackingEntry[] = [],TotalFileSize : number = 0 ){
        this.ARProjectRef = ARProjectRef;
        this.ARProjectName = ARProjectName;
        this.TotalViews = TotalViews;
        this.UniqueViews = UniqueViews;
        this.OSBasedViews = OSBasedViews;
        this.MediumBasedViews = MediumBasedViews;
        this.TotalFileSize = TotalFileSize;
    }
}

export class ARProjectReducedTrackingReport{
    public ARProjectRef : number;
    public ARProjectName : string;
    public TotalViews : number;
    public UniqueViews : number;
    public TotalFileSize : number;

    constructor(ARProjectRef : number = 0, ARProjectName : string = "", TotalViews : number = 0, UniqueViews : number = 0,TotalFileSize : number = 0 ){
        this.ARProjectRef = ARProjectRef;
        this.ARProjectName = ARProjectName;
        this.TotalViews = TotalViews;
        this.UniqueViews = UniqueViews;
        this.TotalFileSize = TotalFileSize;
    }
}

export class ARProjectMediumTrackingReport{
    public ARProjectRef : number;
    public MediumBasedViews : MediumTrackingEntry[];

    constructor(ARProjectRef : number = 0, MediumBasedViews : MediumTrackingEntry[] = []){
        this.ARProjectRef = ARProjectRef;
        this.MediumBasedViews = MediumBasedViews;
    }
}

export class ARPlaylistTrackingReport{
    public ARPlaylistRef : number;
    public ARPlaylistName : string;
    public ARProjectBasedViews : ARProjectGeneralTrackingReport[];

    constructor(ARPlaylistRef : number = 0, ARPlaylistName : string = "", ARProjectBasedViews : ARProjectGeneralTrackingReport[] = []){
        this.ARPlaylistRef = ARPlaylistRef;
        this.ARPlaylistName = ARPlaylistName;
        this.ARProjectBasedViews = ARProjectBasedViews;
    }
}

export class ARPlaylistReducedTrackingReport{
    public ARPlaylistRef : number;
    public ARPlaylistName : string;
    public ARProjectBasedViews : ARProjectReducedTrackingReport[];

    constructor(ARPlaylistRef : number = 0, ARPlaylistName : string = "", ARProjectBasedViews : ARProjectGeneralTrackingReport[] = []){
        this.ARPlaylistRef = ARPlaylistRef;
        this.ARPlaylistName = ARPlaylistName;
        this.ARProjectBasedViews = ARProjectBasedViews;
    }
}

export class ARObjectTypeModelView{
    public ObjectTypeId : number;
    public ObjectTypeName : string;

    constructor(ObjectTypeId : number = 0, ObjectTypeName : string = ""){
        this.ObjectTypeId = ObjectTypeId;
        this.ObjectTypeName = ObjectTypeName;
    }
}

export class GetARObjectTypesModel{
    public ARObjectTypes : ARObjectTypeModelView[];

    constructor(ARObjectTypes : ARObjectTypeModelView[] = []){
        this.ARObjectTypes = ARObjectTypes;
    }
}

export class ARMediumDeleteRequestModel{
    public CustomerId : number;
    public ARMediumId : number;

    constructor(CustomerId : number = 0, ARMediumId : number = 0){
        this.CustomerId = CustomerId;
        this.ARMediumId = ARMediumId;
    }
}

export class ARTrackerTypeModelView{
    public TrackerTypeId : number;
    public TrackerTypeName : string;

    constructor(TrackerTypeId : number = 0, TrackerTypeName : string = ""){
        this.TrackerTypeId = TrackerTypeId;
        this.TrackerTypeName = TrackerTypeName;
    }
}

export class GetARTrackerTypesModel{
    public CustomerId : number;
    public ARTrackerTypes : ARTrackerTypeModelView[];

    constructor(CustomerId : number = 0, ARTrackerTypes : ARTrackerTypeModelView[] = []){
        this.CustomerId = CustomerId;
        this.ARTrackerTypes = ARTrackerTypes;
    }
}

export class ARImageTrackerDeleteRequestModel{
    public CustomerId : number;
    public ARImageTrackerId : number;

    constructor(CustomerId : number = 0, ARImageTrackerId : number = 0){
        this.CustomerId = CustomerId;
        this.ARImageTrackerId = ARImageTrackerId;
    }
}

export class ARProjectStateModelView{
    public StateId : number;
    public StateName : string; 

    constructor(StateId : number = 0, StateName : string = ""){
        this.StateId = StateId;
        this.StateName = StateName;
    }
}

export class GetARProjectStatesModel{
    public ARProjectStates : ARProjectStateModelView[];

    constructor(ARProjectStates : ARProjectStateModelView[] = []){
        this.ARProjectStates = ARProjectStates;
    }
}

export class GetAREngineVersions{
    public AREngineVersions : AREngineViewModel[];

    constructor(AREngineVersions : AREngineViewModel[] = []){
        this.AREngineVersions = AREngineVersions;
    }
}

export class ARTrackingOSTypeModelView{
    public TrackingOSTypeId : number;
    public OSTypeName : string; 

    constructor(TrackingOSTypeId : number = 0, OSTypeName : string = ""){
        this.TrackingOSTypeId = TrackingOSTypeId;
        this.OSTypeName = OSTypeName;
    }
}

export class GetARTrackingOSTypesModel{
    public ARTrackingOSTypes : ARTrackingOSTypeModelView[];

    constructor(ARTrackingOSTypes : ARTrackingOSTypeModelView[] = []){
        this.ARTrackingOSTypes = ARTrackingOSTypes;
    }
}

export class GetARProjectTrackingReportModel{
    public CustomerId : number;
    public ARProjectId : number;
    public TrackingReport : ARProjectGeneralTrackingReport;
    public DateFilter : ReportDateFilter;

    constructor(CustomerId : number = 0, ARProjectId : number = 0, TrackingReport : ARProjectGeneralTrackingReport = new ARProjectGeneralTrackingReport(), DateFilter : ReportDateFilter = new ReportDateFilter()){
        this.CustomerId = CustomerId;
        this.ARProjectId = ARProjectId;
        this.TrackingReport = TrackingReport;
        this.DateFilter = DateFilter;
    }
}

export class GetARPlaylistTrackingReportModel{
    public CustomerId : number;
    public PlaylistId : number;
    public TrackingReport : ARPlaylistReducedTrackingReport;
    public DateFilter : ReportDateFilter;

    constructor(CustomerId : number = 0, PlaylistId : number = 0, TrackingReport : ARPlaylistReducedTrackingReport = new ARPlaylistReducedTrackingReport(), DateFilter : ReportDateFilter = new ReportDateFilter()){
        this.CustomerId = CustomerId;
        this.PlaylistId = PlaylistId;
        this.TrackingReport = TrackingReport;
        this.DateFilter = DateFilter;
    }
}

export class GetTrackingReportOfCustomerARProjectsModel{
    public CustomerId : number;
    public TrackingReport : ARProjectReducedTrackingReport[];
    public DateFilter : ReportDateFilter;

    constructor(CustomerId : number = 0, TrackingReport : ARProjectReducedTrackingReport[] = [], DateFilter : ReportDateFilter = new ReportDateFilter()){
        this.CustomerId = CustomerId;
        this.TrackingReport = TrackingReport;
        this.DateFilter = DateFilter;
    }
}

export class GetFullTrackingReportOfCustomerARProjectsModel{
    public CustomerId : number;
    public TrackingReport : ARProjectGeneralTrackingReport[];
    public DateFilter : ReportDateFilter;

    constructor(CustomerId : number = 0, TrackingReport : ARProjectGeneralTrackingReport[] = [], DateFilter : ReportDateFilter = new ReportDateFilter()){
        this.CustomerId = CustomerId;
        this.TrackingReport = TrackingReport;
        this.DateFilter = DateFilter;
    }
}

export class GetTrackingReportOfCustomerPlaylistsModel{
    public CustomerId : number;
    public TrackingReport : ARPlaylistReducedTrackingReport[];
    public DateFilter : ReportDateFilter;

    constructor(CustomerId : number = 0, TrackingReport : ARPlaylistReducedTrackingReport[] = [], DateFilter : ReportDateFilter = new ReportDateFilter()){
        this.CustomerId = CustomerId;
        this.TrackingReport = TrackingReport;
        this.DateFilter = DateFilter;
    }
}

export class GetFullTrackingReportOfCustomerPlaylistsModel{
    public CustomerId : number;
    public TrackingReport : ARPlaylistTrackingReport[];
    public DateFilter : ReportDateFilter;

    constructor(CustomerId : number = 0, TrackingReport : ARPlaylistTrackingReport[] = [], DateFilter : ReportDateFilter = new ReportDateFilter()){
        this.CustomerId = CustomerId;
        this.TrackingReport = TrackingReport;
        this.DateFilter = DateFilter;
    }
}

export class ARTestingViewModel{
    public ARTestingId : number;
    public TestingCode : string;
    public TestingMode : string;
    public TestingFileRef : number;
    public TestingFilePath : string;
    public TestingCreateTimeStamp : Date;
    public DirectURL : string;

    constructor(ARTestingId : number = 0, TestingCode : string = "", TestingMode : string = "", TestingFileRef : number = 0, TestingFilePath : string = "", TestingCreateTimeStamp : Date = new Date(), DirectURL : string = ""){
        this.ARTestingId = ARTestingId;
        this.TestingCode = TestingCode;
        this.TestingMode = TestingMode;
        this.TestingFileRef = TestingFileRef;
        this.TestingFilePath = TestingFilePath;
        this.TestingCreateTimeStamp = TestingCreateTimeStamp;
        this.DirectURL = DirectURL;
    }
}

export class ARTestingAddUpdateRequestModel{
    public CustomerId : number;
    public ARTestingId : number;
    public TestingMode : string;
    public TestingFileRef : number;

    constructor(CustomerId : number = 0, ARTestingId : number = 0, TestingMode : string = "", TestingFileRef : number = 0){
        this.CustomerId = CustomerId;
        this.ARTestingId = ARTestingId;
        this.TestingMode = TestingMode;
        this.TestingFileRef = TestingFileRef;
    }
}

export class ARProjectChangeLogModelView{
    public ChangeLogId : number;
    public Message : string;
    public TranslationIdentifier : string;
    public Parameters : string[];
    public ChangeLogCollectionReference : number;
    public static PARAMETER_SEPARATOR : string = "{%%}";

    constructor(ChangeLogId : number = 0, Message : string = "", TranslationIdentifier : string= "", Parameters : string[] , ChangeLogCollectionReference : number = 0){
        this.ChangeLogId = ChangeLogId;
        this.Message = Message;
        this.TranslationIdentifier = TranslationIdentifier;
        this.Parameters = Parameters;
        this.ChangeLogCollectionReference = ChangeLogCollectionReference;
    }

    public static constructChangeLogMessage(Message : string, Parameters : string[]) : string{

        let matches = Message.match(/\{.*?\}/g);

        if(matches != null && Parameters != null){
            for(var matchCount = 0; matchCount < matches.length; matchCount++){
                Message = (matchCount < Parameters.length) ? Message.replace(matches[matchCount], Parameters[matchCount]) : Message;
            }
        }

        return Message;
    }
}

export class ARProjectChangeLogCollectionModelView{
    public ProjectLogCollectionId : number;
    public Username : string;
    public Date : Date;
    public ARPRojectReference : number;
    public Messages : ARProjectChangeLogModelView[];

    constructor(ProjectLogCollectionId : number = 0, Username : string = "", LogDate : Date = new Date(), ARPRojectReference : number = 0, Messages : ARProjectChangeLogModelView[] = []){
        this.ProjectLogCollectionId = ProjectLogCollectionId;
        this.Username = Username;
        this.Date = LogDate;
        this.ARPRojectReference = ARPRojectReference;
        this.Messages = Messages;
    }
}

