import { StaticDataStorage } from './../../global-service/static';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { CoreApiService } from './../core-api-services';
import { CoreCommonService } from './../core-common.service';
import { CryptoService } from '../../global-service/Crypto.service';




@Component({
  selector: 'role-selection',
  templateUrl: './role-selection.component.html',
  styleUrls: ['./role-selection.component.css']
})
export class RoleSelectionComponent implements OnInit, OnDestroy {

  //member variables
  UserId: number;
  POLName: string = "Lilavati Hospital";  
  RoleSelectionArray: Array<{}>;
  SelectedRole: {};

  // Subscription to clear later
  userIdSubscription;
  //

  //subscription
  coreApiServiceSubscription;

  constructor(
    private router:Router,
    private coreCommonService: CoreCommonService,
    private coreApiService: CoreApiService,
    private cookieService: CookieService,
    private staticDataStorage:StaticDataStorage,
    private cryptoService:CryptoService
  ) { }

  ngOnInit() {
    this.UserId = this.coreCommonService.getUserId();
    this.RoleSelectionArray = this.staticDataStorage.getRoleSelectionArray;
    this.onSelectionChange(this.RoleSelectionArray[0]);
    this.POLName = this.coreCommonService.getCustomerName();
  }

  getDecryptedRole(string:string):any{
    return this.cryptoService.decryptCryptoToString(string, this.staticDataStorage.getLocalCrypto);
  }



  onSelectionChange(role: {}) {
    this.SelectedRole = role['value'];
    this.coreCommonService.setUserRoleWithEncryption(role['value']);
  }

  onContinueSubmit(){
    const calibrationCookieExists: boolean = this.cookieService.check('calibrationCookie');
    //
    if(this.SelectedRole==this.staticDataStorage.frontdeskRole){
      return false;
    }
    //
    if(!calibrationCookieExists){
      this.router.navigate(["/Calibration"]);
    }else{

      if(this.SelectedRole==this.staticDataStorage.adminRole){
        this.router.navigate(["/POL"])
      }

      if(this.SelectedRole==this.staticDataStorage.consultantRole){        
        this.router.navigate(["/ConsultantSelection"])
      }

    }
    
    //
  }

  ngOnDestroy() {
    //this.coreApiServiceSubscription.unsubscribe();
  }

}
