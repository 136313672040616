import { StaticDataStorage } from './static';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CoreCommonService } from './../core/core-common.service';

@Injectable()
export class AuthGuardService {

    constructor(private router: Router, private coreCommonService: CoreCommonService) { }

    canActivate() {
        if (this.coreCommonService.getUserAuthenticate()) {
            return true;
        }
        else {
            this.router.navigate(['/']);
        }
        return false;
    }
}
