import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreApiService } from './../core-api-services';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CoreCommonService } from './../../core/core-common.service';
import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/do';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {

  coreApiSubscription: Subscription;

  hideA = true;
  hideB = true;
  hideC = true;

  UserId:number;


  @ViewChild('ResetPassword') ResetPassword: ElementRef;

  passwordMapFlag: boolean = false;
  passwordChangedFlag: boolean = false;

  changePassword: FormGroup;
  _password: string = "";

  key:string;

  newPassword = new FormControl('', [Validators.required]);
  retypePassword = new FormControl('', [Validators.required]);

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private coreApiService:CoreApiService,
    private snackBar:MatSnackBar 
  ) { }

  ngOnInit() {
    this.initForm();
    //
    Observable.fromEvent(this.ResetPassword.nativeElement, "keyup")
      .debounceTime(0)
      .distinctUntilChanged()
      .do((data) => {
        if (this.changePassword.value["newPassword"] != this.changePassword.value["retypePassword"]) {
          this.passwordMapFlag = false;
          this.retypePassword.setErrors({ 'incorrect': true });
        } else {
          this.passwordMapFlag = true;
          this.retypePassword.setErrors(null);
        }
      }).subscribe()
    //
    this.activatedRoute.queryParams.subscribe((params: Params) => {      
      //this.key = params.key;
      //console.log(params.key);
      this.coreApiSubscription = this.coreApiService.GetUserByPasswordKey({"GUID": params.key}).subscribe(
        (httpReasult: any) => {
          if (httpReasult.ResponseBody) {
            this.UserId = httpReasult.ResponseBody.UserId;
          }
        }
      );
    });
    //
    
    //
  }



  initForm() {
    let password: String;

    this.changePassword = new FormGroup({
      'newPassword': this.newPassword,
      "retypePassword": this.retypePassword
    });

  }
  peviousRoute() {
    this.location.back();
  }

  onResetPasswordSubmit() {
    const PasswordObj = {
      "NewPassword": this.changePassword.value["newPassword"],
      "UserId": this.UserId,
    }
    this.coreApiSubscription = this.coreApiService.ForgetUserPassword(PasswordObj).subscribe(
      (httpReasult: any) => {
        if (httpReasult) {
          if(httpReasult.ResponseBody.Status){
            this.snackBar.open("Success! user password changed.", "Close", {
              duration: 5000,
              panelClass: ['snackbar-success']
            })
            this.passwordChangedFlag = true;
          }else{
          }
        }
      })
  }


  setPassword() {
    this._password = ""
  }

  continueLogin() {
    this.router.navigate(["/"]);
  }

  ngOnDestroy() {
    try { this.coreApiSubscription.unsubscribe(); } catch (err) { }
  }


}