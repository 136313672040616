import { Component, OnInit, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StaticDataStorage } from './global-service/static';
import { CoreCommonService } from './core/core-common.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent /*implements OnInit*/ {
  title = 'angular-9-i18n';
  //langs = ['en', 'de'];

  isBlankPage : boolean = false;

  constructor(
    private translateService: TranslateService,
    private staticDataStorage:StaticDataStorage,
    private coreCommonService: CoreCommonService,
    private route: ActivatedRoute,
    private router: Router
    ) {}

  public ngOnInit(): void {
    //Language detection
    let browserLang = this.translateService.getBrowserLang();
    //let langPath = this.staticDataStorage.getLanguagePath;
    //console.log("Language: ",browserLang)
   
   /* if (this.staticDataStorage.getLanguages.indexOf(browserLang) > -1) {
    
        this.translateService.setDefaultLang(browserLang);
     
    } else {
      this.translateService.setDefaultLang('en');
    }*/
    this.coreCommonService.setLanguage(null); //automatic
  
    this.translateService.get('errorcodes').subscribe((codes: string) => {
      //console.log(codes);
      this.staticDataStorage.setErrorCodes(codes);
     
    });

    //console.log(this.route);

    this.router.events.subscribe((event) => {   
      if(event instanceof NavigationEnd){
        if(event.url == "/SystemRequirements"){
          this.isBlankPage = true;
        }else{
          this.isBlankPage = false;
        }
      }    
    });
    
  }


  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang.toLowerCase());
  }
}
