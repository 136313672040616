import { GlobalService } from '../../global-service/global.service';
import { StaticDataStorage } from '../../global-service/static';
import { CoreCommonService } from '../../core/core-common.service';
import { CoreApiService } from '../../core/core-api-services';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import 'rxjs/add/operator/share';

@Component({
  selector: 'app-partner-selection',
  templateUrl: './partner-selection.component.html',
  styleUrls: ['./partner-selection.component.css']
})
export class PartnerSelectionComponent implements OnInit, OnDestroy {

  roleSelectionForm: FormGroup;
  selectedCustomerId: number;

  //member variables
  coreApiServiceSubscription: Subscription;
  customerListArray: Array<{}> = [{}];
  UserId: number;

  //

  consultantSelected = new FormControl("", [Validators.required]);


  constructor(
    private router: Router,
    private coreAPIService: CoreApiService,
    private coreCommonService: CoreCommonService,
    private staticDataStorage: StaticDataStorage,
    private cookieService: CookieService,
    private global: GlobalService
  ) { }

  ngOnInit() {

    this.coreApiServiceSubscription = this.coreAPIService.GetRoleList().subscribe(
      (httpReasult: any) => {
        if (httpReasult.ResponseBody) {
          this.coreCommonService.setRoleArray(httpReasult.ResponseBody);
          this.GetCustomerListByUserId();
        }
      })
    //init form
    this.initForm();
    //Get Customer List

  }

  GetCustomerListByUserId() {
    //
    this.coreApiServiceSubscription = this.coreAPIService.GetCustomerListByUserId({ "UserId": this.coreCommonService.getUserId() }).subscribe(
      (httpReasult: any) => {
        if (httpReasult.ResponseBody) {
          this.customerListArray = httpReasult.ResponseBody;
          if(this.customerListArray.length == 1){
            this.selectedCustomerId = this.customerListArray[0]["CustomerId"];
            this.getRoleDetailsAnduserCustomerId();
          }
        }
      })
    //
  }

  onConsultantSelected(event) {
    this.selectedCustomerId = event.CustomerId;
  }

  onNameSelectionSubmit() {
    this.getRoleDetailsAnduserCustomerId();
  }

  getRoleDetailsAnduserCustomerId() {
    this.coreApiServiceSubscription = this.coreAPIService.GetUserCustomerIdAndUserName({ "UserId": this.coreCommonService.getUserId(), "CustomerId": this.selectedCustomerId }).subscribe(
      (httpReasult: any) => {
        if (httpReasult.ResponseBody) {
          //console.log(">>> "+httpReasult.ResponseBody.RoleName)
          this.coreCommonService.setUserRole(httpReasult.ResponseBody.RoleName.toLowerCase());
          this.coreCommonService.setCustomerName(httpReasult.ResponseBody.CustomerName);
          this.coreCommonService.setUserCustomerId(httpReasult.ResponseBody.UserCustomerId);
          this.coreCommonService.setCustomerId(httpReasult.ResponseBody.CustomerId);
          this.getCurrentUserDetails();
        }
      })
  }


  getCurrentUserDetails() {
    this.coreApiServiceSubscription = this.coreAPIService.GetUserCustomerDetailsById({ "UserCustomerId": this.coreCommonService.getUserCustomerId() }).subscribe(
      (httpReasult: any) => {
        if (httpReasult.ResponseBody) {
          this.coreCommonService.setUserDetails(httpReasult.ResponseBody);
          this.global.onUserDetails(this.coreCommonService.getUserDetails());
          this.navigateTo();
        }
      })
  }


  navigateTo() {

    const cookieFlag = this.cookieService.check('calibrationCookie');
    //console.log("User role: ",this.coreCommonService.getUserRole());
    if (this.coreCommonService.getUserRole() == this.staticDataStorage.sAdminRole) {
      //if (cookieFlag) {
        this.router.navigate(["/SuperAdmin"]);
        //return false;
      //}
      //this.router.navigate(["/Calibration"]);
    }
    if (this.coreCommonService.getUserRole() == this.staticDataStorage.adminRole) {
      //if (cookieFlag) {
        this.router.navigate(["/POL"]);
       // return false;
      //}
      //this.router.navigate(["/Calibration"]);
    }
    /*if (this.coreCommonService.getUserRole() == this.staticDataStorage.consultantRole) {
      //if (cookieFlag) {
        //this.router.navigate(["/Consultant"]);
        this.router.navigate(["/learner/ViewAllLearners"]);
        //return false;
      //}
      //this.router.navigate(["/Calibration"]);
    }*/
    if (this.coreCommonService.getUserRole() == this.staticDataStorage.frontdeskRole) {
      //this.router.navigate(["/learner/ViewAllLearners"]);
    }
  }


  initForm() {
    this.roleSelectionForm = new FormGroup({
      'consultantSelected': this.consultantSelected
    });
  }

  ngOnDestroy() {
    this.coreApiServiceSubscription.unsubscribe();
  }

}
