import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationStart, NavigationEnd, Router, NavigationError, NavigationCancel } from '@angular/router';
import { StaticDataStorage } from 'src/app/global-service/static';


@Component({
  selector: 'router-loader',
  templateUrl: './router-loader.component.html',
  styleUrls: ['./router-loader.component.css']
})
export class RouterLoaderComponent implements OnInit, OnDestroy {

  loaderMsg:string;
  loaderFlag:boolean;

  constructor(
    private router:Router,
    private staticDataStorage: StaticDataStorage
    ) { }

  ngOnInit() {
  //
  this.loaderMsg = "";
  //
  this.router.events.forEach((event) => {
    if(event instanceof NavigationStart) {
      this.showLoader();
    }


    if(event instanceof NavigationEnd) {
      this.hideLoader();
    }

    if(event instanceof NavigationCancel) {
      this.hideLoader();
    }

    if(event instanceof NavigationError) {
      this.hideLoader();
    }
    // RoutesRecognized
  });

  this.router.onSameUrlNavigation = 'reload';
  //
}

hideLoader() {
  this.loaderMsg = "";
  this.loaderFlag = false;
}

showLoader() {
  //this.loaderMsg = "Processing view please wait.";
  this.loaderMsg = this.staticDataStorage.getLoaderMsg;
  this.loaderFlag = true;
}

ngOnDestroy(){
}


}
