
import { CoreApiService } from './../core/core-api-services';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

import { CoreCommonService } from './../core/core-common.service';


import * as CryptoJS from 'crypto-js';

@Injectable()

export class CryptoKeyResolver implements Resolve<string> {



  constructor(
    private coreApiService: CoreApiService,
    private coreCommonService: CoreCommonService
  ) { }

  waitForDataToLoad(): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      this.coreApiService.getCryptoKey().map(
        (data) => {
          this.coreCommonService.setcryptoKey(data['Key']);
        }
      ).toPromise()
        .then(
          res => {        
            resolve();
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }
  resolve(): Promise<any> {
    return this.waitForDataToLoad();
  }
}

