import { PartnerSelectionComponent } from './../shared/partner-selection/partner-selection.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './../core/login/login.component';
import { CryptoKeyResolver } from './../global-service/ctypto-resolver';
import { AuthGuardService } from './../global-service/auth-guard.service';
import { ForgetPasswordComponent } from '../core/forget-password/forget-password.component';
import { SystemRequirementsComponent } from '../core/system-requirements/system-requirements.component';

import { SALoginComponent } from '../core/salogin/salogin.component';
import { QRCodeModule } from 'angular2-qrcode';
export const appRoutes: Routes = [
    {
        path: '', resolve: { CryptoKeyResolver },
        children: [
            { path: '', redirectTo: 'Login', pathMatch: 'full' },
            { path: 'Login', component: LoginComponent },
            { path: 'SALogin',component: SALoginComponent },
            { path: 'CustomerList', component: PartnerSelectionComponent, canActivate: [AuthGuardService]},
            { path: 'SystemRequirements', component: SystemRequirementsComponent},
            { path: 'User', loadChildren : './../user/user.module#UserModule' , canActivate: [AuthGuardService]},

            { path: 'POL', 
             /*loadChildren: './../pol/pol.module#PolModule' ,*/
             loadChildren: () => import('./../pol/pol.module').then(m => m.PolModule),
             canActivate: [AuthGuardService]
            },

           
            //{ path: 'Learner', loadChildren: './../learner/learner.module#LearnerModule' , canActivate: [AuthGuardService]},

            { path: 'ForgotPassword', component: ForgetPasswordComponent },
            { 
                path: 'SuperAdmin',/* loadChildren: './../administratorManagement/administratorManagement.module#AdministratorManagementModule', canActivate: [AuthGuardService] },*/
                loadChildren: () => import('./../administratorManagement/administratorManagement.module').then(m => m.AdministratorManagementModule),
                canActivate: [AuthGuardService]
            },
        ],
        runGuardsAndResolvers: 'always'
    },
   
     { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        QRCodeModule,
        RouterModule.forRoot(appRoutes, {useHash: true, onSameUrlNavigation: 'reload'})
    ],
    exports: [RouterModule]
})
export class AppRouterModule { }
