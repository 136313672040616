import { GlobalService } from './../../global-service/global.service';
import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
import { CoreCommonService } from 'src/app/core/core-common.service';
import { StaticDataStorage } from 'src/app/global-service/static';


@Component({
  selector: 'API-loader',
  templateUrl: './API-loader.component.html',
  styleUrls: ['./API-loader.component.css']
})
export class APILoaderComponent implements OnInit, OnDestroy {

  loaderMsg:string;
  loaderFlag:boolean;
  showLoaderSubscription = new Subscription();
  hideLoaderSubscription = new Subscription();

  constructor(
    private global:GlobalService,
    private staticDataStorage: StaticDataStorage
    ) { }

  ngOnInit() {
  //
  this.loaderMsg = "";
  //
  this.showLoaderSubscription = this.global.showGlobalLoaderSubject.subscribe(
    () => {
      this.showLoader();
    }
  );
  this.hideLoaderSubscription = this.global.hideGlobalLoaderSubject.subscribe(
    () => {
      this.hideLoader();
    }
  );
  //
}

hideLoader() {
  this.loaderMsg = "";
  this.loaderFlag = false;
}

showLoader() {
  this.loaderMsg = this.staticDataStorage.getLoaderMsg;//"Processing your request. please wait";
  this.loaderFlag = true;
}

ngOnDestroy(){
  this.showLoaderSubscription.unsubscribe();
  this.hideLoaderSubscription.unsubscribe();
}


}
