import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { CoreApiService } from '../core-api-services';
import { CoreCommonService } from '../core-common.service';
import { StaticDataStorage } from '../../global-service/static';
import { CryptoService } from '../../global-service/Crypto.service';
import { LoginModel } from '../../global-service/global-model';

@Component({
  selector: 'app-salogin',
  templateUrl: './salogin.component.html',
  styleUrls: ['./salogin.component.css']
})

export class SALoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  ForgetPasswordForm: FormGroup;


  coreApiServiceSubscription: Subscription;

  _password: string = "";
  forgetPasswordEmail: string = "";
  forgetPasswordClicked: boolean = false;
  forgetPasswordStatus: boolean = false;

  hide = true;

  userNameControl = new FormControl('', [Validators.required, Validators.email]);
  passwordControl = new FormControl('', [Validators.required]);
  forgetPasswordUserNameControl = new FormControl('', [Validators.required, Validators.email]);

  constructor(

    private coreApiService: CoreApiService,
    private coreCommonService: CoreCommonService,
    private router: Router,
    private staticDataStorage: StaticDataStorage,
    private cryptoService: CryptoService
  ) { }

  ngOnInit() {

    //
    this.coreCommonService.deleteSAUser();
    //
    this.initForm();
    //
  }

  setPassword() {
    this._password = ""
  }

  getCryptoAfterForceExit() {
    //
    if (this.coreCommonService.getForceExit()) {
      this.coreApiService.getCryptoKey().map(
        (data) => {
          this.coreCommonService.setcryptoKey(data['Key']);
        }
      ).subscribe(
        () => {
          this.coreCommonService.setForceExit(false);
          this.continueLoginForm();
        })
    }
    //
  }


  initForm() {
    let password: String;

    this.loginForm = new FormGroup({
      'userName': this.userNameControl,
      'password': this.passwordControl
    });

    this.ForgetPasswordForm = new FormGroup({
      'forgetPasswordUserNameControl': this.forgetPasswordUserNameControl
    });
  }

  getErrorMessage() {
    return this.userNameControl.hasError('required') ? 'You must enter a value' :
      this.userNameControl.hasError('email') ? 'Not a valid email' :
        '';
  }


  onSubmit() {

    if (this.coreCommonService.getForceExit()) {
      this.getCryptoAfterForceExit();
    } else {
      this.continueLoginForm();
    }

  }

  continueLoginForm() {
    const loginUser: LoginModel = new LoginModel(
      this.loginForm.value["userName"],
      this.loginForm.value["password"]
    );
    //
    this.coreApiServiceSubscription = this.coreApiService.adminlogin(loginUser).subscribe(
      (httpReasult: any) => {
        if (httpReasult.ResponseBody) {

          this.coreCommonService.setUserAuthenticate(true);
          this.coreCommonService.setJWTToken(httpReasult.Certificate);
          this.coreCommonService.setUserId(httpReasult.ResponseBody.UserId);
          this.coreCommonService.setCustomerId(httpReasult.ResponseBody.CustomerId);
          this.coreCommonService.setCustomerName(httpReasult.ResponseBody.CustomerName);
          this.coreCommonService.setUserCustomerId(httpReasult.ResponseBody.UserCustomerId);
          //this.router.navigate(["/administratorManagement"]);

        }
      }
    );
  }


  onForgetPasswordSubmit() {
    //
    this.coreApiServiceSubscription = this.coreApiService.SendForgetPassword({ "UserName": this.ForgetPasswordForm.value["forgetPasswordUserNameControl"] }).subscribe(
      (httpReasult: any) => {
        if (httpReasult.ResponseBody) {
          this.forgetPasswordStatus = httpReasult.ResponseBody.Status;
        }
      }
    );
    //
  }
  getDecryptedRole(string: string): any {
    return this.cryptoService.encryptStringCrypto(string, this.staticDataStorage.getLocalCrypto);
  }
  getPasswordErrorMessage() {
    return this.userNameControl.hasError('required') ? 'You must enter a password' : "";
  }

  ngOnDestroy() {
    try {
      this.coreApiServiceSubscription.unsubscribe();
    } catch (err) {

    }

  }

}