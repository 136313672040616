import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { StaticDataStorage } from './../global-service/static';
import { LoginModel } from './../global-service/global-model';


@Injectable()
export class CoreApiService {

    //requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    constructor(
        private http: HttpClient,
        private staticDataStorage: StaticDataStorage
    ) { }

    getCryptoKey(): Observable<boolean> {
        return this.http.post(this.staticDataStorage.getStaticURL + 'Auth/GetCryptoKey', { noAuth: true })
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            );
    }

    login(RequestBody: LoginModel) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Auth/UserLogin'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
             
    }

    adminlogin(RequestBody: LoginModel) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        return this.http.post((this.staticDataStorage.getStaticURL + 'AdminLogin/Login'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }
    saveCalibrationData(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'System/CreateCalibration'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    GetCustomerListByUserId(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetCustomerList'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetUserCustomerIdAndUserName(RequestBody: {}) {
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetUserCustomerId'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetUserCustomerDetailsById(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetUserCustomerDetailsById'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    GetRoleList(){
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetRoleList'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetCountryList(){
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Common/GetCountryList'), requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetStateList(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Common/GetStateList'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetCityList(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Common/GetCityList'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }


    GetUserExistStatus(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetUserExistStatus'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetUserDetailsById(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/GetUserDetailsById'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    UpdateUser(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/UpdateUser'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    ChangeUserPassword(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'User/ChangeUserPassword'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    SendForgetPassword(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Auth/SendForgetPassword'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    GetUserByPasswordKey(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Auth/GetUserByPasswordKey'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    
    ForgetUserPassword(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Auth/ChangeUserPassword'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    UserLogout(RequestBody: {}){
        let requestObj: object = { 'RequestBody': RequestBody };
        let requestHeader: object = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this.http.post((this.staticDataStorage.getStaticURL + 'Auth/UserLogout'), requestObj, requestHeader)
            .map(
                (httpReasult: any) => {
                    return httpReasult;
                }
            )
    }

    
} 
