import { CryptoService } from './../../global-service/Crypto.service';
import { StaticDataStorage } from './../../global-service/static';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { LoginModel } from './../../global-service/global-model';
import { CoreApiService } from './../core-api-services';
import { CoreCommonService } from './../core-common.service';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  copyrightYear: any;
  loginForm: FormGroup;
  ForgetPasswordForm: FormGroup;

  coreApiServiceSubscription: Subscription;
  _password: string = "";

  forgetPasswordEmail: string = "";
  forgetPasswordClicked: boolean = false;
  forgetPasswordStatus: boolean = false;

  hide = true;
  userNameControl = new FormControl('', [Validators.required, Validators.email]);
  passwordControl = new FormControl('', [Validators.required]);

  forgetPasswordUserNameControl = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private coreApiService: CoreApiService,
    private coreCommonService: CoreCommonService,
    private router: Router,
    private staticDataStorage: StaticDataStorage,
    private cryptoService: CryptoService
  ) { }

  ngOnInit() {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return true;
    }

    this.copyrightYear = new Date().getFullYear();
    //this.getCryptoAfterForceExit();
    //
    this.coreCommonService.deleteUser();
    //
    this.initForm();
    //
    if(this.coreCommonService.getDialogRef()) this.coreCommonService.getDialogRef().close();
  }

  

  getCryptoAfterForceExit() {
    //
    if (this.coreCommonService.getForceExit()) {
      this.coreApiService.getCryptoKey().map(
        (data) => {
          this.coreCommonService.setcryptoKey(data['Key']);
        }
      ).subscribe(
        () => {
          this.coreCommonService.setForceExit(false);
          this.continueLoginForm();
        })
    }
    //
  }

  setPassword() {
    this._password = ""
  }


  initForm() {
    let password: String;

    this.loginForm = new FormGroup({
      'userName': this.userNameControl,
      'password': this.passwordControl
    });

    this.ForgetPasswordForm = new FormGroup({
      'forgetPasswordUserNameControl': this.forgetPasswordUserNameControl
    });

  }

  getErrorMessage() {
    return this.userNameControl.hasError('required') ? 'You must enter a value' :
      this.userNameControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  getPasswordErrorMessage() {
    return this.userNameControl.hasError('required') ? 'You must enter a password' : "";
  }


  onSubmit() {
    if (this.coreCommonService.getForceExit()) {
      this.getCryptoAfterForceExit();
    }else{
      this.continueLoginForm();
    }    
    
  }

  continueLoginForm(){
    const loginUser: LoginModel = new LoginModel(
      this.loginForm.value["userName"],
      this.loginForm.value["password"]
    );

    //
    this.coreApiServiceSubscription = this.coreApiService.login(loginUser).subscribe(
      (httpReasult: any) => {
        if (httpReasult.ResponseBody) {
          this.coreCommonService.setUserAuthenticate(true);
          this.coreCommonService.setJWTToken(httpReasult.Certificate);
          this.coreCommonService.setUserId(httpReasult.ResponseBody.UserId);

          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }

          this.router.navigate(["/CustomerList"]);

        }
      }
    );
  }

  onForgetPasswordSubmit() {
    //
    this.coreApiServiceSubscription = this.coreApiService.SendForgetPassword({ "UserName": this.ForgetPasswordForm.value["forgetPasswordUserNameControl"] }).subscribe(
      (httpReasult: any) => {
        if (httpReasult.ResponseBody) {
          this.forgetPasswordStatus = httpReasult.ResponseBody.Status;
        }
      }
    );
    //
  }


  getDecryptedRole(string: string): any {
    return this.cryptoService.encryptStringCrypto(string, this.staticDataStorage.getLocalCrypto);
  }

  ngOnDestroy() {
    try {
      this.coreApiServiceSubscription.unsubscribe();
    } catch (err) {

    }

  }

}
