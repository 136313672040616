import { GlobalApiService } from './../global-service/global-api-services';
import { MaterialModule } from './../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login/login.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { RoleSelectionComponent } from './role-selection/role-selection.component';
import { AuthGuardService } from './../global-service/auth-guard.service';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { CoreCommonService } from './core-common.service';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';

import { SharedModuleModule } from './../shared-module/shared-module.module';

import { SALoginComponent } from './salogin/salogin.component';
import { AppSidebarComponent } from './app-sidebar/app-sidebar.component';
import { RouterModule  } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angular2-qrcode';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SystemRequirementsComponent } from './system-requirements/system-requirements.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModuleModule,
    RouterModule,
    TranslateModule,
    QRCodeModule,
    NgxChartsModule
  ],
  declarations: [LoginComponent,SALoginComponent, AppHeaderComponent, RoleSelectionComponent, AppFooterComponent, ForgetPasswordComponent, AppSidebarComponent, SystemRequirementsComponent],
  providers: [
    AuthGuardService,
    CoreCommonService,
    GlobalApiService
  ],
  exports: [
    LoginComponent,
    AppHeaderComponent,
    AppFooterComponent,
    AppSidebarComponent
  ]
})
export class CoreModule { }
