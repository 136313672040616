import { GlobalApiService } from './../../global-service/global-api-services';
import { CryptoService } from './../../global-service/Crypto.service';
import { StaticDataStorage } from './../../global-service/static';
import { CoreCommonService } from './../core-common.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GlobalService } from './../../global-service/global.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CoreApiService } from './../core-api-services';
/*import { UserIdleService } from 'angular-user-idle';*/
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})

export class AppHeaderComponent implements OnInit, OnDestroy {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  /*@HostListener('window:beforeunload')
  beforeunloadHandler() {return false;}*/
  coreApiSubscription: Subscription;

  userSubscription = new Subscription();
  remainingLicenseSubscription = new Subscription();

  ApiServiceSubscription: Subscription;
  //license
  RemainingLicenses: {};

  userId: number;
  userData: {};
  userRole: string;
  customerName: string;
  showRoleChangeBtn: boolean = false;

  topBangRequired: boolean = false;

  QuestionText: string;

  showLicenseDetails: boolean = false;
  showCalibration: boolean = false;
  showSettings: boolean = false;

  selectedEnviroment : string = "AR";
  showEnviromentButtons : boolean = false;
  hideEnviromentButtons : boolean = true;


  constructor(
    private global: GlobalService,
    private router: Router,
    private coreCommonService: CoreCommonService,
    private staticDataStorage: StaticDataStorage,
    private cryptoService: CryptoService,
    private globalApiService: GlobalApiService,
    private coreApiService:CoreApiService,
    private idle: Idle, private keepalive: Keepalive,
    public translateService: TranslateService,
  ) { 

  
   

  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnInit() {
    
    //'header.changepassword'
     // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(2000);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    this.idle.onTimeout.subscribe(() => {
      // this.idleState = 'Timed out!';
      this.LogOut();
      this.timedOut = true;
      
    });
    //this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    //this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();

    this.selectedEnviroment = this.coreCommonService.getCurrentSelectedEnviroment();
    //console.log(this.selectedEnviroment);
    /*********************************************** */

    this.userSubscription = this.global.userDetailsSubject.subscribe(
      (userData: {}) => {
        this.userId = this.coreCommonService.getUserId();
        this.userData = userData;
        this.customerName = this.coreCommonService.getCustomerName();
        this.userRole = this.coreCommonService.getDecryptedUserRole();
        this.showRoleChangeBtn = false;
        if (this.coreCommonService.getUserRole() == this.staticDataStorage.adminRole) {
          this.showRoleChangeBtn = true;
          this.showCalibration = true;
          this.showSettings = true;
          this.showEnviromentButtons = true;
        }

        if (this.coreCommonService.getUserRole() == this.staticDataStorage.consultantRole) {
          this.showCalibration = true;
        }
      }
    );
    this.remainingLicenseSubscription = this.global.remainingLicensesSubject.subscribe((licenseData) => {
      this.RemainingLicenses = licenseData;
      this.showLicenseDetails = true;
    });



    //
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var url: string = (event.url.replace(/[^\w\s]/gi, '').toLowerCase());
        var topbandFlag = true;
        for (var i = 0; i < this.staticDataStorage.topBandNotRequiredArr().length; i++) {
          if (this.staticDataStorage.topBandNotRequiredArr()[i] == url) {
            topbandFlag = false;
            break;
          }
        }
        this.topBangRequired = topbandFlag;
      }
    });
    //
  }

  NavigateTo(navigation: string) {

    switch (navigation) {
      case "calibration":
        this.coreCommonService.setCurrentRoute(this.router.url);
        this.router.navigate(["/Calibration"]);
        break;

      case "profile":
        this.coreCommonService.setCurrentRoute(this.router.url);
        this.router.navigate(["/User/Profile"]);
        break;

      case "resetPassword":
        this.coreCommonService.setCurrentRoute(this.router.url);
        this.router.navigate(["/User/ResetPassword"]);
        break;

      case "settings":
        this.coreCommonService.setCurrentRoute(this.router.url);
        this.router.navigate(["/POL/Settings"]);
        break;

      default:
        break;
    }
  }

  LogOut() {   

    const LogOutObj = {
      "UserId": this.userId,
    }
    this.coreApiSubscription = this.coreApiService.UserLogout(LogOutObj).subscribe(
      (httpReasult: any) => {
        if (httpReasult) {
          if(httpReasult.ResponseBody.Status){
            this.router.navigate(["#/Login"])
            this.global.onContinueRoute("");
            this.userData = null;
            this.customerName = null;
            this.userRole = null;
            this.coreCommonService.deleteUser();
          }else{
          }
        }
      })    
  }

  changeRole(role: string) {

    this.global.onContinueRoute("");

    this.coreCommonService.setUserRole(role);

    if (this.cryptoService.encryptStringCrypto(role, StaticDataStorage.localCrypto) == this.staticDataStorage.adminRole) {
      this.router.navigate(["/POL"]);      
    }
    if (this.cryptoService.encryptStringCrypto(role, StaticDataStorage.localCrypto) == this.staticDataStorage.consultantRole) {
      //this.router.navigate(["/learner/ViewAllLearners"]);
    }
    if (this.cryptoService.encryptStringCrypto(role, StaticDataStorage.localCrypto) == this.staticDataStorage.frontdeskRole) {
      //this.router.navigate(["/learner/ViewAllLearners"]);
    }

    this.checkUserRoleAndUpdateView(role);

    

  }


  checkUserRoleAndUpdateView(role: string) {

    this.userRole = role;

    if (this.cryptoService.encryptStringCrypto(role, StaticDataStorage.localCrypto) == this.staticDataStorage.adminRole) {
      this.showLicenseDetails = true;
    } else {
      this.showLicenseDetails = false;
    }

    if (this.cryptoService.encryptStringCrypto(role, StaticDataStorage.localCrypto) == this.staticDataStorage.frontdeskRole) {
      this.showCalibration = false;
    } else {
      this.showCalibration = true;
    }
  }


 

  onLogoClicked() {

    //console.log(this.cryptoService.encryptStringCrypto(this.userRole, StaticDataStorage.localCrypto), "     ", this.staticDataStorage.sAdminRole)
    if (this.cryptoService.encryptStringCrypto(this.userRole, StaticDataStorage.localCrypto) == this.staticDataStorage.adminRole) {
      this.router.navigate(["/POL"]);
    }else if(this.cryptoService.encryptStringCrypto(this.userRole, StaticDataStorage.localCrypto) == this.staticDataStorage.sAdminRole) {
      this.router.navigate(["/SuperAdmin/Dashboard"]);
    }else {
      //this.router.navigate(["/learner/ViewAllLearners"]);
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.remainingLicenseSubscription.unsubscribe();
  }

  switchToVREnviroment(){
    this.coreCommonService.setCurrentSelectedEnviroment("VR");
    this.selectedEnviroment = "VR";

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this.router.navigate(["/POL/Dashboard"]);
  }

  switchToAREnviroment(){
    this.coreCommonService.setCurrentSelectedEnviroment("AR");
    this.selectedEnviroment = "AR";

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this.router.navigate(["/POL/Dashboard"]);
  }

}
