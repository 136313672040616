import { Router } from '@angular/router';
import { StaticDataStorage } from './../global-service/static';
import { CryptoService } from './../global-service/Crypto.service';
import { Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ARObjectTypeModelView, ARProjectStateModelView, ARTrackerTypeModelView, ARTrackingOSTypeModelView } from '../global-service/global-model';
import { timeStamp } from 'console';
import {Observable} from 'rxjs/Rx';
import { Headers } from '@angular/http';
import Swal from 'sweetalert2';

@Injectable()
export class CoreCommonService {

  private authenticate: Boolean;

  private cryptoKey: string;

  private JWTToken: string;

  private UserId: number;

  private UserDetailsId: number;

  private UserRole: string;

  private GUID: string;

  private CustomerName: string;

  private UserCustomerId: number;

  private UserDetails: {};

  private currentRoute: string;

  private customerId: number;

  private customerDefaultLanguage: number;

  private roleArray: Array<{}>;

  private forceExit: boolean = false;

  private selectedCustomer: any;

  private selectedApp: any;

  private selectedPlaylist: any;

  private selectedMedia: any;

  private currency: any = "SGD";
  private selectedIndividual: any;

  private dialogRef: any;

  private language: string;

  private availableApplicationTypes : Array<{}>;
  private availableLanguages : Array<{}>;

  private selectedARProjectId : number = 0;
  private selectedARPlaylistId : number = 0;
  private selectedARReportPlaylistId : number = 0;
  private selectedARReportProjectId : number = 0;

  private currentSelectedEnviroment : string = "AR";

  private arImageTypeFileTypes : Array<string> = ["image/bmp", "image/jpeg", "image/png", "image/tiff"];
  private arVideoTypeFileTypes : Array<string> = ["video/mp4", "video/quicktime", "video/x-msvideo", "video/x-ms-wmv"];
  private arModelFileTypes : Array<string> = ["model/gltf-binary", "application/octet-stream", "model/obj"];

  private arImageTypeFileEndings : Array<string> = ["png", "jpg", "jpeg", "tiff", "bmp"];
  private arVideoTypeFileEndings : Array<string> = ["mp4", "avi", "wmv"];
  private arModelFileEndings : Array<string> = ["glb"];

  private availableARObjectTypes : ARObjectTypeModelView[] = [new ARObjectTypeModelView(1, "Image"), new ARObjectTypeModelView(2, "Video"), new ARObjectTypeModelView(3, "Model")];
  private availableARImageTrackerTypes : ARTrackerTypeModelView[] = [new ARTrackerTypeModelView(1, "AR.js")];
  private availableARProjectStates : ARProjectStateModelView[] = [new ARProjectStateModelView(1, "Created"), new ARProjectStateModelView(2, "Published"),
                                                                new ARProjectStateModelView(3, "Changes Pending"), new ARProjectStateModelView(4, "In Maintenance"),
                                                                new ARProjectStateModelView(5, "Offline"), new ARProjectStateModelView(6, "Archived")];
  private availableARTrackingOSTypes : ARTrackingOSTypeModelView[] = [new ARTrackingOSTypeModelView(1, "Other"), new ARTrackingOSTypeModelView(2, "Android"), new ARTrackingOSTypeModelView(3, "PC"), new ARTrackingOSTypeModelView(4, "iOS")];

  private FileExtensionMIMETypeReference : object = {
    "png" : "image/png",
    "jpg" : "image/jpeg",
    "jpeg" : "image/jpeg",
    "bmp" : "image/bmp",
    "tif" : "image/tiff",
    "tiff" : "image/tiff",
    "patt" : "text/plain",
    "json" : "text/plain",
    "txt" : "text/plain",
    "mp4" : "video/mp4",
    "avi" : "video/x-msvideo",
    "glb" : "model/gltf-binary",
    "fbx" : "application/octet-stream",
    "obj" : "application/octet-stream"
  };

  constructor(
    private cryptoService: CryptoService, 
    private staticDataStorage: StaticDataStorage, 
    private router: Router,
    private translateService: TranslateService
    ) { }

  /////////////////

  setLanguage(lang:string){
    if(!lang){
      //If language is not mentioned.
      //Set default language
      let browserLang = this.translateService.getBrowserLang();

      let isCompatibleLang = false;
      this.staticDataStorage.getLanguages.filter(function(item){
        if(item['code'] == browserLang){
          isCompatibleLang = true;
          return;
        } 
      })

      //console.log("isCompatibleLang: ",isCompatibleLang)

      if (isCompatibleLang) {
        this.translateService.setDefaultLang(browserLang.toLowerCase());
      } else {
        this.translateService.setDefaultLang('EN'.toLowerCase());
      }
    }else{
      //Set mentioned language
      this.translateService.setDefaultLang(lang.toLowerCase());
    }

    //Set language specific Error codes
    this.translateService.get('errorcodes').subscribe((codes: string) => {
      this.staticDataStorage.setErrorCodes(codes);
    });
    //Set language specific loader message
    this.translateService.get('loaderMsg').subscribe((msg: string) => {
      this.staticDataStorage.setLoaderMsg(msg);
    });

    this.translateService.get('feedbackMessages').subscribe((msgs: string) => {
      this.staticDataStorage.setFeedbackMessages(msgs);
    })
  }

  getTranslationForKey(translationKey : string) : Observable<any>{
    return this.translateService.get(translationKey);
  }

  get getDefaultLanguage():string{
    return this.translateService.getDefaultLang();
  }

  get getLanguages():Array<any>{
    return this.staticDataStorage.getLanguages;
  }

  getUserAuthenticate() {
    return this.authenticate;
  }

  setUserAuthenticate(bool: Boolean) {
    this.authenticate = bool;
  }

  deleteKey() {
    this.authenticate = false;
  }

  ////////////////////

  setcryptoKey(key: string) {
    this.cryptoKey = key;
  }

  getcryptoKey(): string {
    return this.cryptoKey;
  }

  /////////////////////

  setJWTToken(key: string) {
    this.JWTToken = key;
  }

  getJWTToken(): string {
    return this.JWTToken;
  }

  /////////////////////


  /////////////////////

  setUserId(userId: number) {
    this.UserId = userId;
  }

  getUserId(): number {
    return this.UserId;
  }

  /////////////////////

  /////////////////////
  setUserDetailsId(UserDetailsId: number) {
    this.UserDetailsId = UserDetailsId;
    //console.log("SET USER DETAILS ID: ", UserDetailsId)
  }

  getUserDetailsId(): number {
    return this.UserDetailsId;
  }

  /////////////////////

  /////////////////////

  setUserRole(userRole: string) {
    this.UserRole = this.cryptoService.encryptStringCrypto(userRole, this.staticDataStorage.getLocalCrypto);

  }

  setUserRoleWithEncryption(userRole: string) {
    this.UserRole = userRole;
  }

  getDecryptedUserRole(): string {
    return this.cryptoService.decryptCryptoToString(this.UserRole, this.staticDataStorage.getLocalCrypto);
  }

  getUserRole(): string {
    return this.UserRole;
  }

  /////////////////////////
  setGUID(GUID: string) {
    this.GUID = GUID;
  }

  getGUID(): string {
    return this.GUID;
  }

  /////////////////////


  /////////////////////

  setCustomerName(customerName: string) {
    this.CustomerName = this.cryptoService.encryptStringCrypto(customerName, this.staticDataStorage.getLocalCrypto);
  }

  getCustomerName(): string {
    return this.cryptoService.decryptCryptoToString(this.CustomerName, this.staticDataStorage.getLocalCrypto);
  }

  /////////////////////////


  ////////////////////

  setUserCustomerId(UserCustomerId: number) {
    this.UserCustomerId = UserCustomerId;
  }

  getUserCustomerId(): number {
    return this.UserCustomerId;
  }

  /////////////////////////

  /////////////////////////


  setUserDetails(UserDetails: {}) {
    this.UserDetails = UserDetails;
  }

  getUserDetails(): {} {
    return this.UserDetails;
  }

  /////////////////////////

  /////////////////////////



  setCurrentRoute(currentRoute: string) {
    this.currentRoute = currentRoute;
  }

  getCurrentRoute(): {} {
    return this.currentRoute;
  }

  /////////////////////////

  /////////////////////////


  setCustomerId(customerId: number) {
    this.customerId = customerId;
  }

  getCustomerId() {
    return this.customerId;
  }

  /////////////////////////

  /////////////////////////




  setRoleArray(roleArray: Array<{}>) {
    this.roleArray = roleArray;
  }

  getRoleId(name: string): number {
    var roleId;
    for (var i = 0; i < this.roleArray.length; i++) {
      if (name == this.roleArray[i]["RoleName"]) {
        roleId = this.roleArray[i]["RoleID"];
        break;
      }
    }
    return roleId;
  }

  /////////////////////////

  /////////////////////////

  setForceExit(forceExit: boolean) {
    this.forceExit = forceExit;
  }

  getForceExit(): boolean {    
    return this.forceExit;
  }

  /////////////////////////

  /////////////////////////

  deleteUser() {
    this.authenticate = false;

    this.JWTToken = "";

    this.UserId = 0;

    this.UserRole = "";

    this.CustomerName = "";

    this.UserCustomerId = 0;

    this.UserDetails = {};

    this.currentRoute = "";

    this.customerId = 0;

    this.roleArray = [];

    this.router.navigate(["/"])

  }

  deleteSAUser() {
    this.authenticate = false;

    this.JWTToken = "";

    this.UserId = 0;

    this.UserRole = "";

    this.CustomerName = "";

    this.UserCustomerId = 0;

    this.UserDetails = {};

    this.currentRoute = "";

    this.customerId = 0;

    this.roleArray = [];

    this.router.navigate(["SALogin"])

  }

  getSelectedCustomer(){
    //console.log(this.selectedCustomer);
    return this.selectedCustomer
  }

  setSelectedCustomer(val:any){
    //console.log(val);
    this.selectedCustomer = val;
  }

  getSelectedApp(){
    return this.selectedApp;
  }

  setSelectedApp(val:any){
    this.selectedApp = val;
  }

  getSelectedPlaylist(){
    return this.selectedPlaylist;
  }

  setSelectedPlaylist(val:any){
    this.selectedPlaylist = val;
  }

  getSelectedMedia(){
    return this.selectedMedia;
  }

  setSelectedMedia(val:any){
    this.selectedMedia = val;
  }
  
  getCurrency(){
    return this.currency
  }

  setCurrency(val:any){
    this.currency = val;
  }

  getSelectedIndividual(){
    return this.selectedIndividual
  }

  setSelectedIndividual(val:any){
    this.selectedIndividual = val;
  }

  getDialogRef(){
    return this.dialogRef;
  }

  setDialogRef(val:any){
    this.dialogRef = val;
  }

  //Language
  getLanguage(){
    return this.language;
  }

  /*setLanguage(val:any){
    this.language = val;
  }*/

  getSelectedARProjectId(){
    return this.selectedARProjectId;
  }

  setSelectedARProjectId(arProjectId : number){
    this.selectedARProjectId = arProjectId;
  }

  getSelectedARPlaylistId(){
    return this.selectedARPlaylistId;
  }

  setSelectedARPlaylistId(arPlaylistId : number){
    this.selectedARPlaylistId = arPlaylistId;
  }

  getSelectedARReportPlaylistId(){
    return this.selectedARReportPlaylistId;
  }

  setSelectedARReportPlaylistId(playlistId : number){
    this.selectedARReportPlaylistId = playlistId;
  }

  getSelectedARReportProjectId(){
    return this.selectedARReportProjectId;
  }

  setSelectedARReportProjectId(projectId : number){
    this.selectedARReportProjectId = projectId;
  }

  getCustomerDefaultLanguage(){
    return this.customerDefaultLanguage;
  }

  setCustomerDefaultLanguage(val:number){
    this.customerDefaultLanguage = val;
  }

  getAvailableLanguages(){
    return this.availableLanguages;
  }

  setAvailableLanguages(val: any[]){
    this.availableLanguages = val;
  }

  getAvailableApplicationTypes(){
    return this.availableApplicationTypes;
  }

  setAvailableApplicationTypes(val: any[]){
    this.availableApplicationTypes = val;
  }

  getCurrentSelectedEnviroment(){
    return this.currentSelectedEnviroment;
  }

  setCurrentSelectedEnviroment(newEnviroment : string = "AR"){
    this.currentSelectedEnviroment = newEnviroment
  }

  getMediumFileTypeFromMIMEType(mimeType : string){
    
    if(this.arImageTypeFileTypes.indexOf(mimeType) != -1){
      return 1;
    }

    if(this.arVideoTypeFileTypes.indexOf(mimeType) != -1){
      return 2;
    }

    if(this.arModelFileTypes.indexOf(mimeType) != -1){
      return 3;
    }

    return 0;
  }

  getMediumFileTypeFromFileEnding(fileEnding : string){
    
    if(this.arImageTypeFileEndings.indexOf(fileEnding) != -1){
      return 1;
    }

    if(this.arVideoTypeFileEndings.indexOf(fileEnding) != -1){
      return 2;
    }

    if(this.arModelFileEndings.indexOf(fileEnding) != -1){
      return 3;
    }

    return 0;
  }

  getFileSizeFromByteSize(byteSize : number){
    let SizeIdentifiers = ["B", "KB", "MB", "GB", "TB"];
    let identifierIndex = 0;


    while(byteSize >= 1024){
      byteSize = byteSize / 1024;
      identifierIndex++;
    }

    return byteSize.toFixed(0).toString() + " " + SizeIdentifiers[identifierIndex];

  }

  getAvailableARObjectTypes(){
    return this.availableARObjectTypes;
  }

  setAvailableARObjectTypes(availableARObjectTypes : ARObjectTypeModelView[]){
    this.availableARObjectTypes = availableARObjectTypes;
  }

  getAvailableARImageTrackerTypes(){
    return this.availableARImageTrackerTypes ;
  }

  setAvailableARImageTrackerTypes(availableARImageTrackerTypes : ARTrackerTypeModelView[]){
    this.availableARImageTrackerTypes = availableARImageTrackerTypes;
  }

  getAvailableARProjectStates(){
    return this.availableARProjectStates;
  }

  setAvailableARProjectStates(availableARProjectStates : ARProjectStateModelView[]){
    this.availableARProjectStates = availableARProjectStates;
  }

  getAvailableARTrackingOSTypes(){
    return this.availableARTrackingOSTypes;
  }

  setAvailableARTrackingOSTypes(availableARTrackingOSTypes : ARTrackingOSTypeModelView[]){
    this.availableARTrackingOSTypes = availableARTrackingOSTypes;
  }

  getFileExtensionFromFilename(filename : string){
    let fileSplit = filename.split(".");

    return fileSplit[fileSplit.length-1];
  }

  getMIMETypeFromFilename(filename : string){
    let fileExtension = this.getFileExtensionFromFilename(filename);

    return this.getMIMETypeFromFileExtension(fileExtension);

  }

  getMIMETypeFromFileExtension(fileExtension : string){

    if(this.FileExtensionMIMETypeReference.hasOwnProperty(fileExtension))
      return this.FileExtensionMIMETypeReference[fileExtension];

    return "text/plain";
  }

  getStartOfDayDate(date : Date){

    date = this.getUTCDate(date);
    date.setUTCHours(0,0,0,0);
    return date;
  }

  getEndOfDayDate(date : Date){
    date = this.getUTCDate(date);
    date.setUTCHours(23,59,59,999);
    return date;
  }

  getUTCDate(date : Date){
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()))
  }

  createErrorMessage(message : string = ""){
    Swal.fire({
        icon: 'error',
        title: message,
        confirmButtonColor: "#086cdd"
    });
  }

  createErrorMessageByErrorCode(errorCode : number){
      this.translateService.get('errorCodes.' + errorCode).subscribe((res : string) => {
          this.createErrorMessage(res);
      })
  }

  createSuccessToastMessage(message : string = ""){
      Swal.fire({
          position: 'bottom-end',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500
      });
  }

  createSuccessMessage(message : string = ""){
      Swal.fire({
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500
      });
  }

  createSuccessMessageTranslatePopup(translationKey : string){
      this.translateService.get(translationKey).subscribe((res : string) => {
          this.createSuccessMessage(res);
      })
  }

  createSuccessToastTranslatePopup(translationKey : string){
      this.translateService.get(translationKey).subscribe((res : string) => {
          this.createSuccessToastMessage(res);
      })
  }

  createConfirmDenyDialog(message: string = "", callbackConfirm : any = null, callbackDeny : any = null, confirmBtnColor : string = "#CF4A0C", denyBtnColor : string = "#086cdd"){
      this.translateService.get(["common.confirmBtn", "common.cancelBtn"]).subscribe((res: any) => {
          console.log(confirmBtnColor + " " + denyBtnColor);
          Swal.fire({
              icon: 'warning',
              title: message,
              showDenyButton : true,
              showConfirmButton: true,
              denyButtonText : res["common.cancelBtn"],
              confirmButtonText : res["common.confirmBtn"],
              reverseButtons: true,
              confirmButtonColor : confirmBtnColor,
              denyButtonColor : denyBtnColor

          }).then((result) => {
              if(result.isConfirmed){
                  if(callbackConfirm){
                      callbackConfirm();
                  }
              }

              if(result.isDenied){
                  if(callbackDeny){
                      callbackDeny();
                  }
              }
          });
      })

      
  }

  createConfirmDenyDialogTranslate(translateKey: string = "", callbackConfirm : any = null, callbackDeny : any = null, confirmBtnColor : string = "#CF4A0C", denyBtnColor : string = "#086cdd"){
      this.translateService.get(translateKey).subscribe((res : string) => {
          this.createConfirmDenyDialog(res, callbackConfirm, callbackDeny, confirmBtnColor, denyBtnColor);
      });
  }


}
