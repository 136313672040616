import { Injectable } from '@angular/core';
import { checkServerIdentity } from 'tls';
import { CryptoService } from './Crypto.service';

@Injectable()
export class StaticDataStorage {

  static baseURL: string;
  static gameURL: string;
  static localCrypto: string;
  static RoleSelectionArray: Array<{}>;
  static languageArray: Array<string>;
  static languagePath: string;

  static sAdminRole: string;
  static adminRole: string;
  static consultantRole: string;
  static frontdeskRole: string;

  static CALIBRAION_DIMENSION;

  static OPACITY;

  static consultantDesignationRole: Array<{}>;
  static consultantQualification: Array<{}>;

  static gender: Array<{}>;

  static questionaireaArray: Array<{}>;
  static paymentOptionList: Array<{}>;

  static LicensePrice: number;

  static ErrorCode: {};
  static FeedbackMessages: {};
  static SnackMassageCode: {};
  static loaderMsg: string;
  static ErrorCodeForUnsavedData: {};

  static IEDectionNote: string;

  static passwordSameErrorMsg: string;

  static arMediumFileSizeLimit: number = 50;
  static arImageMarkerFileSizeLimit: number = 10;
  static arProjectFileSizeLimit: number = 10;

  static reportSettings: {};

  constructor(private cryptoService: CryptoService) {
   
    StaticDataStorage.localCrypto = "a1b2c3d4";
    StaticDataStorage.CALIBRAION_DIMENSION = 5;
    StaticDataStorage.OPACITY = "0.5";
    StaticDataStorage.consultantDesignationRole = [
      { "name": "Chief Medical Officer (Ophthalmologist)", "value": "CMO" },
      { "name": "Ophthalmologist", "value": "ophthalmologist" },
      { "name": "Visiting Consultant", "value": "visiting_consultant" },
      { "name": "Microbiologist", "value": "microbiologist" },
      { "name": "Associate Ophthalmologist", "value": "associate_ophthalmologist" }
    ];
    StaticDataStorage.consultantQualification = [
      { "name": "General Ophthalmology" },
      { "name": "Cataract & Refractive" },
      { "name": "Cornea & Refractive" },
      { "name": "Pediatric Ophthalmology" },
      { "name": "Vitreo Retina" },
      { "name": "Oculoplasty" },
      { "name": "Glaucoma" },
      { "name": "Ocular Oncology" },
      { "name": "Optometry" }
    ];
    StaticDataStorage.gender = [
      { "name": "Male" },
      { "name": "Female" }
    ];

    //
    //
    let sAdminString = StaticDataStorage.sAdminRole = this.cryptoService.encryptStringCrypto('superadmin', StaticDataStorage.localCrypto);
    let adminString = StaticDataStorage.adminRole = this.cryptoService.encryptStringCrypto('admin', StaticDataStorage.localCrypto);
    //let consultantString = StaticDataStorage.consultantRole = this.cryptoService.encryptStringCrypto('consultant', StaticDataStorage.localCrypto)
    //let frontdeskString = StaticDataStorage.frontdeskRole = this.cryptoService.encryptStringCrypto('frontdesk', StaticDataStorage.localCrypto)
    //
    StaticDataStorage.RoleSelectionArray = [
      {
        value: sAdminString,
        label: sAdminString
      },
      {
        value: adminString,
        label: adminString
      }/*,
      {
        value: consultantString,
        label: consultantString
      },
      {
        value: frontdeskString,
        label: frontdeskString
      }*/
    ];

    StaticDataStorage.SnackMassageCode = {
      "1":"Success! Media has been uploaded."
    }

    StaticDataStorage.ErrorCode = {
      "Unknown" : "An unknown Error occured",
      "404": "The requested resource could not be found.",
      "401": "Oops! Connection with the server is lost. Please try again.",
      "400": "Oops! Connection with the server is lost. Please try again.",
      "408": "Authorization timed out. Please login again.",
      "500": "Oops! Connection with the server is lost. Please try again.",
      "501": "Network Error.",
      "601": "User already exists.",
      "602": "User does not exist.",
      "603": "Invalid email or password.",
      "604": "Oops! User creation failed. Please try again.",
      "605": "Oops! User update failed. Please try again.",
      "NaN": "Oops! Connection with the server is lost. Please try again.",
      "606": "Customer admin with this Email already exists.",
      "607": "Customer organization name already exists.",
      "608": "Company/Customer code already exists. Please provide a unique company/customer code.",
      "609": "No record available for update.",
      "610": "Payment Type is not selected.",
      "611": "Please update the screen calibration.",
      "613": "Application does not exists.",
      "614": "Application alreay exists",
      "615": "Application key already exists",
      "616": "There is no pending order.",
      "617": "Assignment is already exists.",
      "618": "Invalid Assignment data.",
      "619": "There is no asssignment.",
      "620": "Asssignment already exists.",
      "621": "License is not available.",
      "622": "Application is not assigned.",
      "623": "This assignment is expired.",
      "624": "Attempt is completed.",
      "625": "All attempts have been used.",
      "626": "Previous attempts were not recorded.",
      "627": "Invalid request has occurred.",
      "628": "Media already exists.",
      "629": "Media does not exist .",
      "630": "Localization does not exist.",
      "631": "AR Medium does not exist.",
      "632": "Invalid File Type for AR Medium.",
      "633": "Invalid Tenant.",
      "634": "Missing Image Tracker File.",
      "635": "Missing Image Tracker Pattern.",
      "636": "Invalid Image Tracker Type.",
      "637": "Invalid AR Project State.",
      "638": "An error occurred while generating a Code for your AR Project. Please try again.",
      "639": "Invalid AR Medium.",
      "640": "Invalid AR Project Image.",
      "641": "Invalid Language.",
      "642": "Invlaid AR Project.",
      "643": "Error while publishing AR Project.",
      "644": "Invalid Folder.",
      "645": "Delete Unsuccessfull.",
      "646": "Invalid AR Image Tracker."
    }

    StaticDataStorage.FeedbackMessages = {
      "successMessages" : {
        "changesSaved" : "Saved Changes successfully!",
        "recoredUpdated" : "Record was successfully updated!",
        "recordAdded" : "Record was successfully added!",
        "mediaAdded" : "Media was successfully added!"
      },
      "infoMessages" : {
  
      },
      "errorMessages" : {
        "changesError" : "Changes could not be saved.",
        "unknownError" : "An unknown error occured."
      }
    };

    StaticDataStorage.ErrorCodeForUnsavedData = {
      "header": "Confirm Navigation",
      "body": "Please confirm to save all data before leaving the page."
    }

    StaticDataStorage.passwordSameErrorMsg = "Old password and new password cannot be same.";

    StaticDataStorage.IEDectionNote = "Activities are not available with Internet Explorer. Please use Chrome or Firefox browsers.";
  }

  setStaticURL(url: string) {
    StaticDataStorage.baseURL = url;
  }

  setLanguages(langs: any) {
    //StaticDataStorage.languageArray = langs.split(" ").join("").split(",");
    StaticDataStorage.languageArray = langs;
  }


  get passwordSameErrorMsg(): string {
    return StaticDataStorage.passwordSameErrorMsg;
  }

  setErrorCodes(data: Object){
    StaticDataStorage.ErrorCode = data;
  }

  setFeedbackMessages(data: Object){
    StaticDataStorage.FeedbackMessages = data;
  }

  setLoaderMsg(data: string){
    StaticDataStorage.loaderMsg = data;
  }

  get getLoaderMsg():string{
    return StaticDataStorage.loaderMsg;
  }

  setGameURL(url: string) {
    StaticDataStorage.gameURL = url;
  }
  get getStaticURL(): string {
    return StaticDataStorage.baseURL;
  }

  get getGameURL(): string {
    return StaticDataStorage.gameURL;
  }

  get getLocalCrypto(): string {
    return StaticDataStorage.localCrypto;
  }

  get getRoleSelectionArray(): Array<{}> {
    return StaticDataStorage.RoleSelectionArray;
  }


  get getLanguages(): Array<{}> {
    return StaticDataStorage.languageArray;
  }
  get sAdminRole(): string {
    return StaticDataStorage.sAdminRole;
  }
  get adminRole(): string {
    return StaticDataStorage.adminRole;
  }
  get consultantRole(): string {
    return StaticDataStorage.consultantRole;
  }
  get frontdeskRole(): string {
    return StaticDataStorage.frontdeskRole;
  }

  get consultantDesignationRole(): Array<{}> {
    return StaticDataStorage.consultantDesignationRole;
  }
  get questionaireaArray(): Array<{}> {
    return StaticDataStorage.questionaireaArray;
  }
  get consultantQualification(): Array<{}> {
    return StaticDataStorage.consultantQualification
  }
  get gender(): Array<{}> {
    return StaticDataStorage.gender
  }

  get OPACITY(): string {
    return StaticDataStorage.OPACITY
  }

  get ErrorCode(): {} {
    return StaticDataStorage.ErrorCode;
  }

  get FeedbackMessage(): {}{
    return StaticDataStorage.FeedbackMessages;
  }

  get ErrorCodeForUnsavedData(): {} {
    return StaticDataStorage.ErrorCodeForUnsavedData;
  }

  get IEDectionNote(): string {
    return StaticDataStorage.IEDectionNote;
  }

  getFeedbackMessage(identifier : string){
    let identifierSplit : string[] = identifier.split('.');

    let FeedbackMessages = StaticDataStorage.FeedbackMessages;
    for(let identifierIndex : number = 0; identifierIndex < identifierSplit.length; identifierIndex++){
      if(FeedbackMessages.hasOwnProperty(identifierSplit[identifierIndex])){
        FeedbackMessages = FeedbackMessages[identifierSplit[identifierIndex]];
      }else{
        return "Unknown Feedback Message of Identifier: " + identifier;
      }
    }

    return FeedbackMessages.toString();

  }

  getErrorCodeMessage(code : number){
    //console.log("Error code: " + code);
    if(StaticDataStorage.ErrorCode.hasOwnProperty(code))
      return StaticDataStorage.ErrorCode[code];
    
    return StaticDataStorage.ErrorCode["Unknown"];
  }

  getLearnerVisionDataForFt(): Array<{}> {
    return [
      { "seq": 0, "value": "Select", "logmar": "" },
      { "seq": 19, "value": "20 / 630", "logmar": "+1.5" },
      { "seq": 18, "value": "20 / 500", "logmar": "+1.4" },
      { "seq": 17, "value": "20 / 400", "logmar": "+1.3" },
      { "seq": 16, "value": "20 / 320", "logmar": "+1.2" },
      { "seq": 15, "value": "20 / 250", "logmar": "+1.1" },
      { "seq": 14, "value": "20 / 200", "logmar": "+1.0" },
      { "seq": 13, "value": "20 / 160", "logmar": "+0.9" },
      { "seq": 12, "value": "20 / 125", "logmar": "+0.8" },
      { "seq": 11, "value": "20 / 100", "logmar": "+0.7" },
      { "seq": 10, "value": "20 / 80", "logmar": "+0.6" },
      { "seq": 9, "value": "20 / 63", "logmar": "+0.5" },
      { "seq": 8, "value": "20 / 50", "logmar": "+0.4" },
      { "seq": 7, "value": "20 / 40", "logmar": "+0.3" },
      { "seq": 6, "value": "20 / 32", "logmar": "+0.2" },
      { "seq": 5, "value": "20 / 25", "logmar": "+0.1" },
      { "seq": 4, "value": "20 / 20", "logmar": "0" },
      { "seq": 3, "value": "20 / 16", "logmar": "-0.1" },
      { "seq": 2, "value": "20 / 12.5", "logmar": "-0.2" },
      { "seq": 1, "value": "20 / 10", "logmar": "-0.3" }
    ]
  }



  getLearnerVisionDataForMeter(): Array<{}> {
    return [
      { "seq": 0, "value": "Select", "logmar": "" },
      { "seq": 1, "value": "6 / 3", "logmar": "-0.3" },
      { "seq": 2, "value": "6 / 3.8", "logmar": "-0.2" },
      { "seq": 3, "value": "6 / 4.8", "logmar": "-0.1" },
      { "seq": 4, "value": "6 / 6", "logmar": "0" },
      { "seq": 5, "value": "6 / 7.5", "logmar": "+0.1" },
      { "seq": 6, "value": "6 / 9.5", "logmar": "+0.2" },
      { "seq": 7, "value": "6 / 12", "logmar": "+0.3" },
      { "seq": 8, "value": "6 / 15", "logmar": "+0.4" },
      { "seq": 9, "value": "6 / 19", "logmar": "+0.5" },
      { "seq": 10, "value": "6 / 24", "logmar": "+0.6" },
      { "seq": 11, "value": "6 / 30", "logmar": "+0.7" },
      { "seq": 12, "value": "6 / 38", "logmar": "+0.8" },
      { "seq": 13, "value": "6 / 48", "logmar": "+0.9" },
      { "seq": 14, "value": "6 / 60", "logmar": "+1.0" },
      { "seq": 15, "value": "6 / 75", "logmar": "+1.1" },
      { "seq": 16, "value": "6 / 95", "logmar": "+1.2" },
      { "seq": 17, "value": "6 / 120", "logmar": "+1.3" },
      { "seq": 18, "value": "6 / 150", "logmar": "+1.4" },
      { "seq": 19, "value": "6 / 190", "logmar": "+1.5" }

    ]
  }


  getLearnerNearDropDown(): Array<string> {
    return [
      "Select",
      "N4",
      "N5",
      "N6",
      "N8",
      "N10",
      "N12",
      "N14",
      "N18",
      "N24",
      "N30",
      "N36",
      "N42",
      "N48"
    ]
  }

  topBandNotRequiredArr(): Array<string> {
    return [
      "",
      "customerlist",
      "userresetpassword",
      "calibration"
    ]
  }

  sidebarNotRequiredArr(): Array<string> {
    return [
      "",
      "customerlist",
      "userresetpassword",
      "calibration",
      "Login"
    ]
  }

  get paymentOptionList(): Array<{}> {
    return StaticDataStorage.paymentOptionList;
  }

  setLicensePrice(price: number) {
    StaticDataStorage.LicensePrice = price;
  }

  getLicensePrice(): number {
    return StaticDataStorage.LicensePrice;
  }

  setFileSizeLimits(fileSizeLimits : object){
    if(fileSizeLimits.hasOwnProperty("arMedium")){
      StaticDataStorage.arMediumFileSizeLimit = fileSizeLimits["arMedium"];
    }

    if(fileSizeLimits.hasOwnProperty("arImageMarker")){
      StaticDataStorage.arImageMarkerFileSizeLimit = fileSizeLimits["arImageMarker"];
    }

    if(fileSizeLimits.hasOwnProperty("arPojectImage")){
      StaticDataStorage.arProjectFileSizeLimit = fileSizeLimits["arPojectImage"];
    }
  }

  getARMediumFileSizeLimit(){
    return StaticDataStorage.arMediumFileSizeLimit;
  }

  getARImageMarkerFileSizeLimit(){
    return StaticDataStorage.arImageMarkerFileSizeLimit;
  }

  getARProjectImageFileSizeLimit(){
    return StaticDataStorage.arProjectFileSizeLimit;
  }

  setReportSettings(reportSettings : object){
    StaticDataStorage.reportSettings = reportSettings;
  }

  getReportSettings(){
    return StaticDataStorage.reportSettings;
  }
}