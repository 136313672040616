export function createRandomId(num) {
    var text = "";
    var possible = "123456789012345678901234567890";

    for (var i = 0; i < num; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return parseInt(text);
}

export function createRandomKey(num) {
    var text = "";
    var possible = "123456789012345678901234567890ABCDEFGHIJKLMNOPQESTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for (var i = 0; i < num; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

export function generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


export function setPPC() {
    return Math.round((getPixelPerCM() / 5) * 100) / 100;
}

export function setPPMM() {
    return Math.round((getPixelPerCM() / 50) * 100) / 100;
}

export function getPixelPerCM() {
    var CalibrationBox = document.getElementById("CalibrationBox");
    var PPC = document.defaultView.getComputedStyle(CalibrationBox, null).getPropertyValue('width');
    return parseFloat(PPC);
}

export function formatMMDDYYYY(matDate: any): string {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dateString: string;
    var date: any = new Date(matDate).getDate();
    date = (date < 10) ? ("0" + date) : (date);
    //var month: any = (new Date(matDate).getMonth() + 1);
    var month: any = (new Date(matDate).getMonth());
    //month = (month < 10) ? ("0" + month) : (month);
    month = monthNames[month];
    var year: number = new Date(matDate).getFullYear();
    dateString = date + " " + month + " " + year;
    return dateString;
}


export function formatHrsMinutes(matDate: any): string {
    var timeString: string;
    var date: any = new Date(matDate);
    timeString = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    return timeString;
}

export function detectIE(): boolean {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return true;
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number    
        return true;
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return true;
    }

    // other browser
    return false;
}


