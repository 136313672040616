import { PreventNegativeDirective } from './ignoreNegative.directive';
import { ClickOutside } from './click-outside';
import { NgModule } from '@angular/core';
import { InputPatternDirective } from './input-pattern.directive';
import { AllowNegativeDirective } from './AllowNegative.directive';
import { QRCodeModule } from 'angular2-qrcode';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  imports:[
    QRCodeModule,
    NgxChartsModule
  ],
  declarations: [
    ClickOutside,
    InputPatternDirective,
    PreventNegativeDirective,
    AllowNegativeDirective,
    
  ],
  exports: [
    ClickOutside,
    InputPatternDirective,
    PreventNegativeDirective,
    AllowNegativeDirective
  ],
  entryComponents: [
    
  ]
})
export class SharedModuleModule { }
