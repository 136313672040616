import { Directive, ElementRef, OnInit, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[AllowNegative]'
})
export class AllowNegativeDirective implements OnInit {

  @Input('AllowNegative') AllowNegative: string;

  regex = /^[0-9,+-.]*$/;

  constructor(private el: ElementRef) {
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.allowNegaiveValue(event);
  }


  allowNegaiveValue(event) {
    //const e = <KeyboardEvent>event;
    //const ch = String.fromCharCode(e.keyCode);
    const e = <KeyboardEvent>event;
    // const ch = String.fromCharCode(e.keyCode);
    const ch = e.key;
    if (ch.toLowerCase() === "tab") {
      return;
    }
    if (this.regex.test(ch)) {
        return;
    }
    e.preventDefault();
  }


  ngOnInit() {

  }

}