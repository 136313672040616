import { Directive, ElementRef, OnInit, OnDestroy, Input, HostListener } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import * as $ from 'jquery';

@Directive({
  selector: '[InputPattern]'
})
export class InputPatternDirective implements OnInit {

  elementSubscription: Subscription;

  regex = /^[a-zA-Z0-9! #,;:'"/@$%\^&*)(+=._-]*$/;

  @Input('InputPattern') InputPattern: string;

  constructor(private el: ElementRef) {
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.noSpecialChars(event);
    //this.noWhiteSpace(event);
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    this.noPasteDataChars(event);
  }

  noSpecialChars(event) {
    const e = <KeyboardEvent>event;
    // const ch = String.fromCharCode(e.keyCode);
    const ch = e.key;
    if (this.regex.test(ch)) {
      if (event.which === 32) {
        var tempData = event.target.value.replace(/^\s+/, '');
        if (tempData.length === 0) {
          event.preventDefault();
        } else {
          return;
        }
      } else {
        return;
      }
    }
    e.preventDefault();
  }

  noWhiteSpace(event) {
    //return ("Surajit");//event.target.value
    const e = <KeyboardEvent>event;
    event.target.value = $.trim(event.target.value);
    //e.preventDefault();
  }


  noPasteDataChars(event) {
    const e = <ClipboardEvent>event;
    const pasteData = e.clipboardData.getData('text/plain');
    if (this.regex.test(pasteData)) {
      return pasteData.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    }
    e.preventDefault();
  }

  ngOnInit() {

  }

}