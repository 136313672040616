import { GlobalService } from './../../global-service/global.service';
import { Router, NavigationStart, NavigationError, NavigationCancel, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as $ from 'jquery';

import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/filter';


@Component({
  selector: 'global-modal',
  templateUrl: './global-modal.component.html',
  styleUrls: ['./global-modal.component.css']
})
export class GlobalModalComponent implements OnInit {

  globalPopupSubscription = new Subscription();
  popupElement: {} = null;

  holdCancelledRoute: string;

  constructor(private router: Router, private global: GlobalService) { }

  ngOnInit() {
    //
    this.popupElement = null;
    //
    this.globalPopupSubscription = this.global.globalPopupSubject.subscribe(
      (errorString: {}) => {
        //console.log(errorString)
        this.popupElement = errorString;
      }
    );
    //

    this.router.events
        .filter(e => e instanceof NavigationEnd)
        .pairwise().subscribe((e) => {
            this.global.setPreviousRoute(e[0]["url"]);
        });

    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.close();
      }

      if (event instanceof NavigationEnd) {
        if (event.url == "/Calibration") {
          $('#application').addClass("calibrationBody");
           $('#AppContainer').addClass("calibrationBody");
        } else {
          $('#application').removeClass("calibrationBody");
          $('#AppContainer').removeClass("calibrationBody");
        }
      }

      if (event instanceof NavigationCancel) {
        this.holdCancelledRoute = event.url;
        //this.popupElement = "This action was blocked due to security breach. Please refreh the browser and try again.";
        //return false;
      }

      /*if (event instanceof NavigationError) {
        this.popupElement = "This action was blocked due to security breach. Please refreh the browser and try again.";
      }*/

      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
    //
  }

  ContinueRouting() {
    this.global.onContinueRoute(this.holdCancelledRoute);
  }

  close() {
    this.popupElement = null;
  }

}
