import { StaticDataStorage } from './../../global-service/static';
import { CoreCommonService } from './../core-common.service';
import { GlobalService } from './../../global-service/global.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, ViewChildren, AfterViewChecked } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.css']
})
export class AppSidebarComponent implements OnInit, AfterViewChecked, OnDestroy {

  baseUrl: String;
  sidebarRequired: Boolean = false;
  userRole: any;

  currentSection: String;
  currentAutoTab: any;
  currentEnviroment: string;

  //Menu show/hide
  mHome: Boolean = false;
  mApps: Boolean = false;
  mPartners: Boolean = false;
  mIndividuals: Boolean = false;
  mReports: Boolean = false;
  mParticipants: Boolean = false;
  mGallery: Boolean = false;
  mSettings: Boolean = false;
  mPlaylist: Boolean = false;

  mARMedium: Boolean = false;
  mARImageTracker: Boolean = false;
  mARProject : Boolean = false;
  mARPlaylist : Boolean = false;
  mARReport : Boolean = false;
  mARTesting : Boolean = false;

  tabActivated: Boolean = false;

  coreApiSubscription: Subscription;

  userSubscription = new Subscription();
  remainingLicenseSubscription = new Subscription();

  ApiServiceSubscription: Subscription;

  selectedItem: any;

  @ViewChild('tab1') tab1: any;
  @ViewChild('tab2') tab2: any;
  @ViewChild('tab3') tab3: any;
  @ViewChild('tab4') tab4: any;
  @ViewChild('tab5') tab5: any;
  @ViewChild('tab6') tab6: any;
  @ViewChild('tab7') tab7: any;
  @ViewChild('tab8') tab8: any;

  @ViewChild('tabAR1') tabAR1: any;
  @ViewChild('tabAR2') tabAR2: any;
  @ViewChild('tabAR3') tabAR3: any;
  @ViewChild('tabAR4') tabAR4: any;
  @ViewChild('tabAR5') tabAR5: any;
  @ViewChild('tabAR6') tabAR6: any;

  constructor(
    private router: Router,
    private global: GlobalService,
    private staticDataStorage: StaticDataStorage,
    private coreCommonService: CoreCommonService
  ) { }

  ngOnInit(){
    this.mHome = this.mApps = this.mPartners = this.mIndividuals = this.mReports = this.mParticipants = true;

    this.currentEnviroment = this.coreCommonService.getCurrentSelectedEnviroment();

    this.userSubscription = this.global.userDetailsSubject.subscribe(
     
      (userData: {}) => {
      
        //console.log("user role: ", this.coreCommonService.getUserRole(), this.staticDataStorage.sAdminRole)
       this.userRole = this.coreCommonService.getDecryptedUserRole();
        //console.log("role: , ", this.userRole)
        if (this.coreCommonService.getUserRole() == this.staticDataStorage.sAdminRole) {
       
          this.mApps = this.mPlaylist = this.mPartners = this.mIndividuals = this.mReports = this.mGallery = this.mSettings = true;
          this.mARMedium = this.mARImageTracker = this.mARProject = this.mARPlaylist = this.mARReport = this.mARTesting = true;
          this.mParticipants = false;
          this.baseUrl = "/SuperAdmin";
        }else if (this.coreCommonService.getUserRole() == this.staticDataStorage.adminRole) {
         
          this.mApps = this.mPlaylist = this.mParticipants = this.mReports = this.mGallery = this.mSettings = true;
          this.mARMedium = this.mARImageTracker = this.mARProject = this.mARPlaylist = this.mARReport = this.mARTesting = true;
          this.mPartners = this.mIndividuals = false;
          this.baseUrl = "/POL";
        }else if (this.coreCommonService.getUserRole() == this.staticDataStorage.consultantRole) {
          this.mARMedium = this.mARImageTracker = this.mARProject = this.mARPlaylist = this.mARReport = this.mARTesting = false;
          this.mHome = this.mApps = this.mParticipants = true;
          this.baseUrl = "/Customer";
        }
        this.sidebarRequired = true;
        
        if(this.coreCommonService.getUserRole()){
          this.checkDisplayLoad(this);
        }

      }
    );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
       
        let urlStr:String = this.router.url.toString()
       
        /*if(urlStr.toLowerCase().indexOf("dashboard")>-1) this.currentSection = "Dashboard";

        else */if(urlStr.toLowerCase().indexOf("partners")>-1) this.currentSection = "Partners";
        else if(urlStr.toLowerCase().indexOf("addpartner")>-1) this.currentSection = "Partners";

        else if(urlStr.toLowerCase().indexOf("application")>-1) this.currentSection = "Application";
        else if(urlStr.toLowerCase().indexOf("editapplication")>-1) this.currentSection = "Application";

        else if(urlStr.toLowerCase().indexOf("playlist")>-1 && urlStr.toLowerCase().indexOf("arplaylist") == -1) this.currentSection = "Playlist";
        else if(urlStr.toLowerCase().indexOf("editplaylist")>-1 && urlStr.toLowerCase().indexOf("arplaylist") == -1) this.currentSection = "Playlist";

        else if(urlStr.toLowerCase().indexOf("individuals")>-1) this.currentSection = "Individuals";
        else if(urlStr.toLowerCase().indexOf("addindividual")>-1) this.currentSection = "Individuals";

        else if(urlStr.toLowerCase().indexOf("participant")>-1) this.currentSection = "Participants";

        else if(urlStr.toLowerCase().indexOf("report")>-1 && urlStr.toLowerCase().indexOf("arreport") == -1) this.currentSection = "Reports";
        else if(urlStr.toLowerCase().indexOf("settings")>-1) this.currentSection = "Settings";

        else if(urlStr.toLowerCase().indexOf("gallery")>-1) this.currentSection = "Gallery";

        else if(urlStr.toLowerCase().indexOf("armedium")>-1) this.currentSection = "ARMedium";

        else if(urlStr.toLowerCase().indexOf("arimagetracker")>-1) this.currentSection = "ARImageTracker";

        else if(urlStr.toLowerCase().indexOf("arproject")>-1) this.currentSection = "ARProject";

        else if(urlStr.toLowerCase().indexOf("arplaylist")>-1) this.currentSection = "ARPlaylist";

        else if(urlStr.toLowerCase().indexOf("arreport")>-1) this.currentSection = "ARReport";

        else if(urlStr.toLowerCase().indexOf("artesting")>-1) this.currentSection = "ARTesting";

        /*var url: string = (event.url.replace(/[^\w\s]/gi, '').toLowerCase());
        var sidebarFlag = true;
        for (var i = 0; i < this.staticDataStorage.sidebarNotRequiredArr().length; i++) {
          if (this.staticDataStorage.sidebarNotRequiredArr()[i] == url) {
            sidebarFlag = false;
            break;
          }
        }
        this.sidebarRequired = sidebarFlag;*/

        
        if(this.coreCommonService.getUserRole()){
          //console.log("CURRENT SELECTION: ", this.currentSection);
          this.displayLinkActive();
        }
      }
      
    });

    
  }

  //Check if tabs are loaded. Else recall again
  checkDisplayLoad(scope){
    if(!scope.tab1){
      setTimeout(scope.checkDisplayLoad, 500, scope);
    }
  }

  ngAfterViewChecked() {
    if(this.currentSection && !this.currentAutoTab)
    this.displayLinkActive();
  }


  displayLinkActive(){
    //console.log("this.tab1 ", this.tab1)
   
    //if(!this.tab1) return; //if not viewchild loaded
    
    if(this.currentAutoTab){
      if(this.currentAutoTab.nativeElement){
        this.currentAutoTab.nativeElement.className = this.currentAutoTab.nativeElement.className.split(" active")[0];
      }
      
      //this.currentAutoTab = null;
      //return;
    }

    //console.log(">>> ",this.currentSection)

    try{

      switch(this.currentSection){
        case "Settings":/*Dashboard":*/
        this.tab1.nativeElement.className = this.tab1.nativeElement.className +" active";
        this.currentAutoTab = this.tab1;
        break;
        case "Partners":
        this.tab3.nativeElement.className = this.tab3.nativeElement.className +" active";
        this.currentAutoTab = this.tab3;
        break;
        case "Application":
        this.tab2.nativeElement.className = this.tab2.nativeElement.className +" active";
        this.currentAutoTab = this.tab2;
        break;
        case "Playlist":
        this.tab8.nativeElement.className = this.tab8.nativeElement.className +" active";
        this.currentAutoTab = this.tab8;
        break;
        case "Individuals":
        this.tab4.nativeElement.className = this.tab4.nativeElement.className +" active";
        this.currentAutoTab = this.tab4;
        break;
        case "Reports":
        this.tab5.nativeElement.className = this.tab5.nativeElement.className +" active";
        this.currentAutoTab = this.tab5;
        break;
        case "Participants":
        this.tab6.nativeElement.className = this.tab6.nativeElement.className +" active";
        this.currentAutoTab = this.tab6;
        break;
        case "Gallery":
        this.tab7.nativeElement.className = this.tab7.nativeElement.className +" active";
        this.currentAutoTab = this.tab7;
        break;
        case "ARMedium":
        this.tabAR1.nativeElement.className = this.tabAR1.nativeElement.className +" active";
        this.currentAutoTab = this.tabAR1;
        break;
        case "ARImageTracker":
        this.tabAR2.nativeElement.className = this.tabAR2.nativeElement.className +" active";
        this.currentAutoTab = this.tabAR2;
        break;
        case "ARProject":
        this.tabAR3.nativeElement.className = this.tabAR3.nativeElement.className +" active";
        this.currentAutoTab = this.tabAR3;
        break;
        case "ARPlaylist":
        this.tabAR4.nativeElement.className = this.tabAR4.nativeElement.className +" active";
        this.currentAutoTab = this.tabAR4;
        break;
        case "ARReport":
        this.tabAR5.nativeElement.className = this.tabAR5.nativeElement.className +" active";
        this.currentAutoTab = this.tabAR5;
        break;
        case "ARTesting":
        this.tabAR6.nativeElement.className = this.tabAR6.nativeElement.className +" active";
        this.currentAutoTab = this.tabAR6;
        break;
      }

    }catch(ex){
      this.currentAutoTab = null;
    }

    this.currentSection = null;
 
  }

  addClass(element) {
    var arr;
    arr = element.className.split(" ");
    if (arr.indexOf('active') == -1) {
      element.className += " " + 'active';
    }
  }

  ngOnDestroy() {
    if(this.ApiServiceSubscription != null){
      this.ApiServiceSubscription.unsubscribe();
    }

    if(this.userSubscription != null){
      this.userSubscription.unsubscribe();
    }

    this.currentSection = null;
    //this.router.events = null;
    //this.paginator.page.unsubscribe();
  }

}
